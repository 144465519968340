import { FACEBOOK_PIXEL, IS_PRODUCTION } from "../../config";
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching


const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class FacebookPixel{
    constructor(){
        if(IS_PRODUCTION){
           // this.initialize();
        }
    }

    initialize = () => {
        this.pixel = ReactPixel;
        this.pixel.init(FACEBOOK_PIXEL, advancedMatching, options);
    }

    pageview = () => {
        if(!IS_PRODUCTION){return}
        this.pixel.pageView();
    }

    track = (name, data) => {
        if(!IS_PRODUCTION){return}
        this.pixel.track(name, data);
    }

    trackSingle = (name, data) => {
        if(!IS_PRODUCTION){return}
        this.pixel.trackSingle(name, data);
    }

}


const FacebookPixelSingleton = new FacebookPixel();

export default FacebookPixelSingleton;