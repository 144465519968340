import queryString from 'query-string';
import store from '../redux/store';
import { setNotifcation } from '../redux/actions/notification';


class NotificationHandler{
    constructor(){}

    async onError({type, err, item, show=true}){
        await store.dispatch(setNotifcation({
            type : type,
            code : err.status,
            message : err.message,
            item : item,
            show : show
        }))
    }

    async onNotification({type="Nofication", message, item={}, show=true}){
        await store.dispatch(setNotifcation({
            type : type,
            message : message,
            code : 200,
            item : item,
            show : show
        }))
    }
}



const NotificationHandlerSingleton = new NotificationHandler();

export default NotificationHandlerSingleton;