import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import PackContainer from '../PackContainer';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import $ from 'jquery';

const LEFT = 1000;
class HorizontalPackList extends Component{

    constructor(props){super(props); this.state = {isAllLeft : true};}


    organizeButtons = () => {
        setTimeout( () => {
            var container = $(`#list-${this.props.id}`);
            var isAllLeft = container.scrollLeft() === 0;
            var isAllRight = container[0].scrollWidth - container.width() - container.scrollLeft() === 0;
            this.setState({isAllLeft, isAllRight});
        }, 500)

    }

    back = (item) => {
        // Your ID to scroll to
        var container = $(`#list-${this.props.id}`);
        var scrollAmount = Math.min(400, container.scrollLeft());
        container.animate({
            scrollLeft: '-=' + scrollAmount 
        }, 500); // Adjust the speed as needed
        this.organizeButtons();
    }

    forward = () => {
        // Your ID to scroll to
        var container = $(`#list-${this.props.id}`);
        
        var remainingScroll = container[0].scrollWidth - container.width() - container.scrollLeft();
        var scrollAmount = Math.min(400, remainingScroll);
        container.animate({
            scrollLeft: '+=' + scrollAmount 
        }, 500); // Adjust the speed as needed
        this.organizeButtons();
    }

    render = () => {
        var { title, data, id } = this.props;
        const { isAllLeft, isAllRight } = this.state;

        return (
            <div className='outer-horizontal-list'>
                <div className='horizontal-list'>
                    <div className='release-list'>
                        <h3>{title}</h3>
                        <div className='icon-container-right'>
                            <button className={`${isAllLeft ? 'grey-icon' : ''}`} disabled={isAllLeft} onClick={() => this.back()}>
                                <ArrowLeft/>
                            </button>   
                            <button  className={`${isAllRight ? 'grey-icon' : ''}`} disabled={isAllRight}  onClick={() => this.forward()}>
                                <ArrowRight/>
                            </button>   
                        </div>
                    </div>
                    <div className='outer-horizontal-list-grid'>
                        <div  id={`list-${id}`}  className='horizontal-list-grid'>
                            {data.map((pack, index) => {
                                return <PackContainer key={index} pack={pack}/>
                            })}
                        </div>       
                    </div>

                </div>  
            </div>
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(HorizontalPackList);