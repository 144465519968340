import { Component } from 'react';
import { methods } from './methods';

class Connection extends Component {

    constructor(props){
        super(props);
    }

    register = async ({username, password, email, name}) => {
        return (await methods.registerUser({username, password, email, name})).data.data;
    }

    login = async ({email, password}) => {
        return (await methods.loginUser({email, password})).data.data;
    }

    googleLogin = async ({googleBearerToken}) => {
        return (await methods.googleLogin({googleBearerToken})).data.data;
    }

    auth = async ({user_id, bearerToken}) => {
        return (await methods.authUser({user_id, bearerToken})).data.data;
    }

    requestResetPassword = async ({email}) => {
        return (await methods.requestResetPassword({email})).data.data;
    }

    resetPassword = async ({token, password, email}) => {
        return (await methods.resetPassword({token, password, email})).data.data;
    }

    getSounds = async ({filter}) => {
        return (await methods.getSounds(filter)).data.data;
    }

    getPacks = async ({filter}) => {
        return (await methods.getPacks(filter)).data.data;
    }

    getPackById = async (params) => {
        return (await methods.getPackById({id : params.pack_id, ...params})).data.data;
    }

    getFilters = async () => {
        return (await methods.getFilters()).data.data;
    }

    getTops = async ({type, timeline}) => {
        return (await methods.getTops({type, timeline})).data.data;
    }

    getStats = async ({type, timeline}) => {
        return (await methods.getStats({type, timeline})).data.data;
    }

    searchBySound = async ({user_id, bearerToken, filter}) => {
        return (await methods.searchBySound({user_id, bearerToken, filter})).data.data;
    }

    searchByPack = async ({user_id, bearerToken, filter}) => {
        return (await methods.searchByPack({user_id, bearerToken, filter})).data.data;
    }

    getMySounds = async ({user_id, bearerToken, filter}) => {
        return (await methods.getUsersSounds({user_id, bearerToken, filter})).data.data;
    }

    getMyPacks = async ({user_id, bearerToken, filter}) => {
        return (await methods.getUsersPacks({user_id, bearerToken, filter})).data.data;
    }

    getMyFavoritesSounds = async ({user_id, bearerToken, filter, isUser}) => {
        return (await methods.getMyFavoritesSounds({user_id, bearerToken, filter, isUser})).data.data;
    }

    getMyFavoritesPacks = async ({user_id, bearerToken, filter, isUser}) => {
        return (await methods.getMyFavoritesPacks({user_id, bearerToken, filter, isUser})).data.data;
    }

    buySound = async ({user_id, bearerToken, sound_id}) => {
        return (await methods.buySound({user_id, bearerToken, id : sound_id})).data.data;
    }

    buyPack = async ({user_id, bearerToken, pack_id}) => {
        return (await methods.buyPack({user_id, bearerToken, id : pack_id})).data.data;
    }

    addSoundToFavorites = async ({user_id, bearerToken, sound_id}) => {
        return (await methods.addSoundToFavorites({user_id, bearerToken, id : sound_id})).data.data;
    }

    addPackToFavorites = async ({user_id, bearerToken, pack_id}) => {
        return (await methods.addPackToFavorites({user_id, bearerToken, id : pack_id})).data.data;
    }

    getUsersMostFavorites = async () => {
        return (await methods.getUsersMostFavorites()).data.data;
    }
};


const APIConnection = new Connection();

export default APIConnection;