import * as React from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import {INTERCOM_APP_ID } from '../../../config';


const ChatWidget = () => (
  <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}/>
);



export default ChatWidget;