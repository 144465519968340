import React, { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import FastMarquee from '../../../../components/FastMarquee';
import CountDownTimer from '../../../../components/CountDownTimer';
import { CAMPAIGN_CODE, CAMPAIGN_URL } from '../../../../../config';
import GoogleAnalyticsSingleton from '../../../../classes/GoogleAnalytics';
import CommunityIcon from '../../../../components/CommunityIcon';
import ReactPlayer from 'react-player'
import { stripePayments } from '../../../../../assets/images';
import CountUp from 'react-countup';

class MainContainer extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }
  
    render = () => {
        let LINK = `${CAMPAIGN_URL}?prefilled_promo_code=${CAMPAIGN_CODE}`
        const { packs, totalSounds=120000 } = this.props;
        let now = new Date();
        return (
            <div className='container-promo'>      
                <div className='text-center text-main main-container'>
                    <h1 className='title'> Browse from <strong><CountUp enableScrollSpy className='h1 text-bold text-primary' end={`+${totalSounds}`} start={`+${totalSounds-2000}`}  duration={300}/></strong> exclusive sounds. </h1>
                    <h3 className='text-grey' style={{margin : 'auto',marginTop : 15, width : '80%'}}>The only subscription you need</h3>
                    {/*<div className='container-2'>
                        <div className='container-outline'>
                            <h4 className='text-greyish'>Normal Price</h4>
                            <h3 className='text-striked text-grey'>$197/month</h3>
                        </div>
                        <div className='container-outline outline-primary'>
                            <h4 className='text-grey'>Exclusive Price</h4>
                            <h3 className='text-primary'>$17/month</h3>
                        </div>
                    </div>*/}
                   
                 <div className='subscription-discount-box'>
                                <div className='countdown-box'>
                                    <h3 className='text-grey text-center'> 87% DISCOUNT </h3>
                                    <h6 className='text-grey'> Offer Ends in </h6>
                                    <h2 className='inline text-primary'><CountDownTimer 
                                        id={'promotion-credits_1'}
                                        date={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)}
                                    /> </h2>
                                    <h4 className='text-grey-small'>{`Hrs : Mins : Secs`}</h4>
                                </div>
                            </div>    
                    <h5 className='text-grey down-text text-bold'></h5>
                    <a href={LINK} className='btn btn-animation'>
                        <h3>Susbcribe for $1.97/mo</h3>
                    </a>
                    <img src={stripePayments} style={{width : 150, margin : 'auto', marginTop : 20}}/>
                    {/*<h5 className='text-grey down-text text-bold'>Secure Payments by Stripe </h5>*/}
                    <div className='commu-container'>
                        <CommunityIcon/>
                    </div>
                    <div className='marquee-container landing-page'>
                        <FastMarquee clickable={false} packs={packs}/>
                    </div>
                    <div className='margin-container'>
                        <div className='flex-container text-center'>
                           
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
    
}

export default MainContainer;