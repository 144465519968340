import { SET_PACKS } from "../actions/packs";

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PACKS : {
            return [].concat(action.action);
        }
      default:
        return state;
    }
  }
  