import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';


class FilterListContainer extends Component{
    constructor(props){
        super(props)
        this.state = {
            itemSelected : {},
        }
    }

 

    render = () => {
        return (
            <div className='list-structure'>
                <div className='text-center'>
                    <h4>Pack</h4>
                </div>
                <div/>
                <div>
                    <h4>Sample</h4>
                </div>
                <div className='wave-container'/>
                <div className='taglist-container'>
                    <h4 className='text-center'>Time</h4>
                </div>
                <div className='taglist-container'>
                    <h4 className='text-center'>Key</h4>
                </div>
                <div className='taglist-container'>
                    <h4 className='text-center'>BPM</h4>
                </div>
                <div className='icons-container'/>
                <div/>

            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(FilterListContainer);
