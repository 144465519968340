import {Component} from 'react';
import './index.scss';
import { Animation } from '../Animation';
import { connect } from 'react-redux';
import SoundWave from '../SoundWave';
import { FavoriteOutlined, Add, FavoriteRounded, Info, PlayArrow, StopCircleOutlined, DownloadRounded, DownloadOutlined, FileDownloadOutlined, FavoriteBorderOutlined, MoreVertOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import UserAPI from '../../classes/User';
import { setPlaySound } from '../../redux/actions/playSound';
import store from '../../redux/store';
import { setPlayHistory } from '../../redux/actions/playHistory';
import { setDownload } from '../../redux/actions/downloadItem';
import ModalSound from '../ModalSound';
import { formatDuration, getCSSVariableValue } from '../../classes/Helpers';

class Card extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    change = () => {
        this.child.change();
    }

    stopSound = async () => {
        await store.dispatch(setPlaySound({
            ...this.props.item,
            toStop : true,
            toPlay : false
        }));
    }

    handlePlay = async () => {
        this.props.onClick(this.props.item);
        const {playHistory, playSound} = this.props;

        if(playSound._id == this.props.item._id){
            /* Same Sound */
            if(playSound.toStop == true){
                /* Was Stopped, play now */
                await this.playSound();
            }else{
                /* Was Playing, stop now */
                await this.stopSound();
            }
        }else{
            /* New Sound */            
            await this.playSound();
        }
    }

    playSound = async () => {
        const { playHistory } = this.props;
        /* Play Sound */
        this.props.onClick(this.props.item);
        /* Check if sound is in history */
        let exists = (playHistory.filter(e => e._id === this.props.item._id).length > 0);

        if(!exists){
            /* Add to history */
            await store.dispatch(setPlayHistory({
                ...this.props.item
            }))
        }
        /* Play Sound */
        await store.dispatch(setPlaySound({
            ...this.props.item,
            toPlay : true,
            toStop : false
        }));
    }
    

    onMouseLeave = () =>{
        this.setState({open : false});
    }

    onBuy = async () => {
        this.setState({isLoading : true});
        let res = await UserAPI.buySound({sound : this.props.item});
        if(res.status == 200){
            this.props.item.isOwned = true;
        }
        this.setState({isLoading : false});
    }

    downloadFile = async () => {
        await store.dispatch(setDownload(this.props.item));
    }

    addToFavorite = async () => {
        this.setState({isLoading : true}); 
        let res = await UserAPI.addSoundToFavorites({sound : this.props.item});
        if(res.status == 200){
            this.props.item.isFavorite = !this.props.item.isFavorite;
        }
        this.setState({isLoading : false});
    }

    openModal3Dots = () => {
        this.modalDots.open();
    }

    render = () => {
        const {open, isLoading} = this.state;
        const {item, index, playSound} = this.props;
        var isPlaying = false;
        var isThisSoundInPlayer = playSound._id == item._id;
        if(isThisSoundInPlayer){
            isPlaying = playSound.toPlay ? true : false;
        }

        return(
            <div id={`sound-${this.props.item._id}`} 
                className={`list-structure hover-structure ${isThisSoundInPlayer ? 'list-active' : ''}`}>    
                <div onClick={this.handlePlay}>
                    <Animation small={true} image={item.imageUrl} change={this.change}/>
                </div>
                <div onClick={this.handlePlay}>
                    <IconButton className='align-center' aria-label="Example">
                        {isPlaying ? <StopCircleOutlined style={{color : getCSSVariableValue('--background-color-inverse')}}/> :  <PlayArrow style={{color : getCSSVariableValue('--background-color-inverse')}}/>}
                    </IconButton>
                </div>
                <div className='small-container container-card-absolute-small' onClick={this.handlePlay}>
                    <div className='list-name'>
                        <h4 className='name' style={{margin : 0}}>{item.name}</h4>
                    </div>
                    <div className='align-horizontal align-left text-left list-tags'>
                        <p key={index} className='list-tag'>{item.type}</p>
                        {item.tags.map((tag,index) => {
                            return (
                                <p key={index} className='list-tag'>{tag}</p>
                            )
                        })}
                         {item.instruments.map( (instrument, index) => {
                            return (
                                <p key={index} className='list-tag'>{instrument}</p>
                            )
                        })}
                         {item.genres.map( (genre, index) => {
                            return (
                                <p key={index} className='list-tag'>{genre}</p>
                            )
                        })}
                    </div>
                    
                </div>
                <div className='wave-container' onClick={this.handlePlay}>
                    <div className='gradient-overlay'/>
                    <SoundWave 
                        soundUrl={item.url}
                        id={item._id}
                        ref={(child) => this.soundwave = child}
                        handlePlay={this.onClick} 
                        small={true}
                    />
                </div>
                <div className='taglist-container' onClick={this.handlePlay}>
                    <div className='gradient-overlay'/>
                    <div className='taglist-text'>
                        <h5 className='vertical-align  text-grey'>{formatDuration(item["duration"]) || "--"}</h5>   
                    </div>
                </div>
                <div className='taglist-container'onClick={this.handlePlay}>
                    <div className='taglist-text'>
                        <h5 className='vertical-align text-grey'>
                            {item.keys.length > 0 ? `${item.keys[0]} ${item.keys[1] ? item.keys[1] : ''}`: "--"}
                        </h5>   
                    </div>
                </div>
                <div className='taglist-container'onClick={this.handlePlay}>
                    <div className='taglist-text'>
                        <h5 className='vertical-align  text-grey'>{item["bpm"] || "--"}</h5>   
                    </div>
                </div>
                <div className='icons-container align-right'>
                    <div className='gradient-overlay'/>
                    {item.isFavorite ? 
                        <IconButton aria-label="Example" className=' favorite-icon' onClick={this.addToFavorite}>
                            <FavoriteRounded/>
                        </IconButton>
                    :     
                        <IconButton aria-label="Example" onClick={this.addToFavorite}>
                            <FavoriteBorderOutlined style={{color : getCSSVariableValue('--background-color-inverse')}}/>
                        </IconButton>
                    }
                    {item.isOwned ? 
                        <Tooltip className={"tooltip"} title={`Download`}>
                            <IconButton aria-label="Download" onClick={this.downloadFile}>
                                <FileDownloadOutlined/>
                            </IconButton>
                        </Tooltip>
                    :                     
                        <Tooltip className={"tooltip"} title={`${item.cost} Credits`}>
                            <IconButton disabled={isLoading} onClick={this.onBuy} aria-label="Buy Sample">
                                <Add style={{color : getCSSVariableValue('--background-color-inverse')}} />
                            </IconButton>
                        </Tooltip>
                    }
                    <>
                        <ModalSound onBuy={this.onBuy} sound={item} ref={(child) =>  this.modalDots = child} id={`${item._id}`} open={this.state.openModal3Dots} keyItem={`${item._id}`}/>
                        <IconButton id={`modal-${item._id}`} className='modal-selector' onClick={this.openModal3Dots} aria-label="Example">
                            <MoreVertOutlined style={{color : getCSSVariableValue('--background-color-inverse')}}/>
                        </IconButton>
                    </>

                </div>
                  {open ? <div className='align-center align small-container'>
                        <button className='button align-center align btn' style={{margin  :'auto', width : '100%'}}>
                            <h4 className='text-center'>Download</h4>
                        </button>
                    </div> : null}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        playSound : state.playSound,
        ref : state.ref,
        playHistory : state.playHistory
    };
  }

 export default connect(mapStateToProps, null, null, { forwardRef: true })(Card);
