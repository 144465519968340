import { SET_ROUTE } from "../actions/route";

const initialState = {  
    path : "/",
    keyword : "landing"
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ROUTE : {
            return {...action.action}
        }
      default:
        return state;
    }
  }
  