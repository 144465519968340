import './index.scss';
import { Footer } from "../../../components";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import HeaderContainer from '../../LandingPage/HeaderContainer';
import Testimonials from '../../LandingPage/Testimonials';
import APIConnection from '../../../api';
import { MetaTags } from 'react-meta-tags';
import MainContainer from './MainContainer';
import SoundContainer from './SoundContainer';
import TopBar from '../../../components/TopBar';
import GoogleAnalyticsSingleton from '../../../classes/GoogleAnalytics';
import ReactPlayer from 'react-player';

class Campaign10GBPage extends Component{
    constructor(props){super(props);this.state = {packs : [], sounds : [], totalSounds : 100, totalPacks : 100, loading : true}}
    

    componentDidMount(){
        GoogleAnalyticsSingleton.pageview('campaign-10gb');
        this.setPacksUI();
    }

    setPacksUI = async () => {
        let res = await APIConnection.getPacks({filter : {size : 10}});
        let res_2 = await APIConnection.getSounds({filter : {size : 40}});
        const { message, status } = res;
        if(status !== 200){
            console.log("res", res);
        }else{
            this.setState({
                loading : false,
                packs : message.data, 
                sounds : res_2.message.data,
                totalSounds : res_2.message.total,
                totalPacks : message.total, 
            });
        }
    }


    render = () => {
        const { packs, totalPacks, sounds, totalSounds, loading } = this.state;
        
        return (
            <>  
                <>{ (!packs.length || loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title>Vertate | 10GB for FREE</title>
                    <meta name="description" content="Sign up and Access our Exclusive Library" />
                    <meta property="og:title" content="Vertate | Access our Exclusive Library" />
                </MetaTags>
                {/* Header */}
                <TopBar text={'87% DISCOUNT TODAY'}/>
                <HeaderContainer justLogo={true}/>
                <MainContainer packs={packs}/>
                <SoundContainer sounds={sounds}/>
                <div className=''>
                    <h1 className='text-center text-primary title'>Access a library full of quality sounds</h1>
                    <h3 className='text-grey text-center container'>Used by producers worldwide</h3>
                    <div className='video-container'>   
                        <ReactPlayer playIcon={true} controls={true} style={{margin : 'auto', padding : 0}} width={'100%'} height={'auto'} url='https://vertate.b-cdn.net/Sequence%2001.mp4' />
                    </div>
                </div>
                <Footer width={100} full={false}/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(Campaign10GBPage);
