  
export const SET_PACKS = 'SET_PACKS';

export function setPacks(data) {
    return {
      type: SET_PACKS,
      action : data
    };
}
  
  