import { Link, useNavigate } from "react-router-dom";
import './index.scss';
import { Button } from "../Button";
import { createAvatar } from '@dicebear/core';
import { shapes } from '@dicebear/collection';
import { Component } from "react";
import { connect } from "react-redux";
import ButtonSubscribePromotion from "../ButtonSubscribePromotion";
import { TollOutlined } from "@mui/icons-material";
import DarkSwitch from '../DarkSwitch'


const UserData = (props) => {
    
    const navigate = useNavigate();

    const { user } = props;
    let isAuthenticated = user._id ? true : false;
    var avatar, dataURI = '';
    if(isAuthenticated){
        avatar = createAvatar(shapes, {
            seed: user.email
        }); 
        dataURI = avatar.toDataUriSync();   
    }    
    
    const register = () => {
        navigate('/signup')
    }

    const isSubscribed = false;

    return (
        <div className="top-header">
            {isAuthenticated ?  
            <div className="grid-container">

                <ButtonSubscribePromotion/>
                <div className="credits-container">
                    <TollOutlined/>
                    <h4 className="text-black text-max">{user.credits} <span className="text-grey">Credits</span></h4>
                </div>
                <div className="switch-container">
                    <DarkSwitch/>
                </div>
            </div>
            : 
                <div >
                    <div className="inline">
                        <Button onClick={register} text={'Try for FREE'}/>
                    </div>
                </div>
            }
        </div>
    )
  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(UserData);