  
export const SET_DOWNLOAD = 'SET_DOWNLOAD';

export function setDownload(data) {
    return {
      type: SET_DOWNLOAD,
      action : data
    };
}
  
  