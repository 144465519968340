import { Component } from 'react';
import './index.scss'
import { CloseOutlined } from '@mui/icons-material';


class Modal extends Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen : false    
        }
    }

    open = () => {
        this.setState({isOpen : true});
        if(document.getElementById(`modal-${this.props.keyItem}`)){
            document.getElementById(`modal-${this.props.keyItem}`).classList.replace('hideModal', "showModal");
        }
    }

    renderMiddleModal = () => {
        const { keyItem, children } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <div className='background-modal'/>
                <div className='modal-middle'>
                    <div className='closeIcon'>
                        <button onClick={() => this.setState({isOpen : false})}>
                            <CloseOutlined/>
                        </button>
                    </div>
                    {children}
		  	    </div>
            </>
			
        )
    }

    render = () => {
        const { keyItem, children, middle } = this.props;
        const { isOpen } = this.state;
        if(!isOpen){return (<div></div>)}
        if(middle){return this.renderMiddleModal()}
        return (
			<div id={`modal-${keyItem}`} key={`modal-${keyItem}`} className='modal modal-box showModal'>
                {children}
		  	</div>
        )
    }
    
}

export default Modal;
