import {Component, useState} from 'react';
import './index.scss';
import { Animation } from '../Animation';
import Card from 'react-animated-3d-card'
import SoundWave from '../SoundWave';

const tags = [
    {
        key : "time",
    },
    {
        key : "key",
    },
    {
        key : "bpm",
    }
]

export class ExpandedCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            isClicked : false,
            item : {}
        }
    }

    onClick = () => {
        //this.props.onClick();
        this.setState({isClicked : !this.state.isClicked})
        this.soundwave.handlePlay();
    }

    unfocus = () =>{
        this.props.unfocus();
    }
    
    render = () => {
        const {open, item} = this.props;
        return(
            <div>
                <button onClick={this.unfocus} className='card-container-absolute'></button>
                <div className='card-expanded'>
                    <Card
                        onClick={this.onClick}
                        style={{
                            backgroundColor: '#292929',
                            width: '300px',
                            height: '500px',
                            cursor: 'none'
                        }}
                    >
                        <div>
                        <Animation blob={this.props.blob} ref={(child) => this.child = child} change={this.change}/>
                            <div className='upper-card'>
                                {/*<SoundWaveVisualizer blob={this.props.blob} ref={(child) => this.child = child} change={this.change}/>*/}
                            </div>
                            <div className='down-card'>
                                <div className='small-container container-card-absolute-max'>
                                    <h2 className='text-left' style={{margin : 0}}>{item.title}</h2>
                                    <h6 className='text-left text-grey' style={{margin : 0}}>#{item.id}</h6>
                                </div>
                                {/* Type - Common, Premium, Rare */}
                                <div>

                                </div>
                                <div className='align-horizontal small-container'>
                                    {item.tags.map((tag,index) => {
                                        return (
                                            <p key={index} className='tag inline'>{tag}</p>
                                        )
                                    })}
                                </div>
                                {/* Soundwave */}
                                <div style={{marginTop : 30}}>
                                    <SoundWave  
                                        id={1}
                                        ref={(child) => this.soundwave = child}
                                        handlePlay={this.onClick} 
                                    />
                                </div>
                                 {/* Sample Variables - Frequency, BPM, Type */}
                                <div className='attributes-container grid-container' style={{gridTemplateColumns : "33% 33% 33%"}}>
                                    {tags.map( (tag,index) => (
                                        <div className='align-center' key={index}>
                                            <h4 className='text-center'>
                                                {tag.value}
                                            </h4>
                                            <h5 className='tag'>
                                                {tag.key}
                                            </h5>
                                        </div>
                                    )
                                    )}
                                </div>
                                {/* Value of Sample */}
                                <div className='economic-containers'>
                                    <h4 className='inline'>
                                        2 
                                    </h4>
                                    <h5 className='white-text inline' style={{marginLeft : 3}}>
                                        credits
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </Card> 
                    {/*<button className='align-center btn-card btn ' style={{margin  :'auto', width : '100%'}}>
                        <h4 className='text-black text-center'>DOWNLOAD</h4>
                                    </button>*/}
                </div>
            </div>
        )
    }
}
