import './index.scss'
import { Component } from 'react';
import { shapes } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';

export class Animation extends Component{

    constructor(props){super(props)}

    render = () => {
        const data = "dfiguhjsdfg98eweur";
        const { image, width } = this.props;
        const avatar = createAvatar(shapes, {
            seed: data,
            // ... other options
          });     
        const dataURI = avatar.toDataUriSync();      

        const sizeClass = 'image-card'
        return(
            <img className={sizeClass} src={image ? image : dataURI} width={width || '100%'}/>
        )
    }

}