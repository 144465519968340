import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'

class CheckBox extends Component{
    constructor(props){
        super(props)
        this.state = {
            checked : false
        }
    }

	handleChange = (e) => {
        this.props.handleChange(e);
	  };

    
    render = () => {
        const { filters, keyItem, valueItem } = this.props;
        var isChecked = false;
        if(filters && filters[keyItem].length){
            isChecked = filters[keyItem].includes(valueItem);
        }
        return (
			<input checked={isChecked} onChange={() => {}} onClick={this.handleChange} type='checkbox'>
            </input>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(CheckBox);
