const logo_white = require('../images/logo-white-no-bg.png');
const logo_color = require('../images/logo-blue-no-bg.png');
const logo_grey = require('../images/logo-grey-no-bg.png');
const stripePayments = require('../images/s.png');
const loadingGif = require('../images/loading.gif');
const image_web = require('../images/web.png');

export {
    logo_white,
    logo_color,
    image_web,
    loadingGif,
stripePayments,
    logo_grey
}