import { SET_SEARCH_QUERY } from "../actions/searchQuery";

const initialState = '';

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_QUERY : {
            return action.action ? action.action.toString() : '';
        }
      default:
        return state;
    }
  }
  