import { Component } from 'react';
import { connect } from 'react-redux';
import PackContainer from '../PackContainer';
import SeeMore from '../SeeMore';
import { Pagination } from '@mui/material';
import { formatNumberWithCommas } from '../../classes/Helpers';

class PacksList extends Component{
    constructor(props){super(props)}


    onChangePage = async (e, page) => {
        await this.props.onChangePage(page);
    }

    render = () => {
        const {packs, pages, page} = this.props;
        const children =  packs.map( (pack, index) => {
            return (
                <PackContainer key={index} pack={pack} />
            )
        });

        return (
            <div>
                <h5 className='amounts-container'>{formatNumberWithCommas(this.props.total)} results</h5>
                <div className='list-container absolute-scrollable-pack absolute-scrollable-item'>
                    <div className='inside'>
                        <div className='list-container-child'>
                            {children}
                        </div>
                        <div className='pagination-container'>
                            <Pagination page={page} onChange={this.onChangePage} count={pages} shape="rounded" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

  export default connect(mapStateToProps)(PacksList);
