import { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import FastMarquee from '../../../components/FastMarquee';
import CommunityIcon from '../../../components/CommunityIcon';

class ThirdPage extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){

    }

    render = () => {
        return (
                <>
                    <div className='thirdpage text-center'>
                        <div className='text-container'>
                            <h2 className='text-primary title'>No more creative block!</h2>
                            <h3 className='text-grey' style={{marginTop : 10}}> Find your new signature sounds in seconds...</h3>
                        </div>
                      
                        <Link to={'/signup'} className='btn btn-primary'>
                            <h3 className='text title'>Sign up today for free</h3>
                        </Link>
                        <div className='commu-container'>
                            <CommunityIcon/>
                        </div>

                        {/*<div className='marquee-list'>
                            <FastMarquee packs={this.props.packs}/>
                        </div>   */} 
                    </div>
                </>
            )
        }
    
}

export default ThirdPage;