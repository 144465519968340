import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Button, Icon } from '@material-ui/core';
import { PlayArrow, SkipPrevious, SkipNext, StopCircleOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Animation } from '../Animation';
import SliderContainer from '../SliderContainer';
import SoundProgressBar from '../SoundProgressBar';
import store from '../../redux/store';
import { setPlaySound } from '../../redux/actions/playSound';
import { loadingGif } from '../../../assets/images';
import { getCSSVariableValue, truncateString } from '../../classes/Helpers';

class Soundplayer extends Component{
    constructor(props){
        super(props); 
        this.state = {}
    }

    onChange = async (isPlaying) => {
        if(isPlaying){
            //stop
            await store.dispatch(setPlaySound({
                ...this.props.playSound,
                toStop : true,
                toPlay : false
            }));
        }else{
            //play
            await store.dispatch(setPlaySound({
                ...this.props.playSound,
                toStop : false,
                toPlay : true
            }));
        }
    }

    goToPrevSound = async () => {
        const { playSound, playHistory } = this.props;
        let currentIndex = playHistory.map((e)=> e._id).indexOf(playSound._id);
        await store.dispatch(setPlaySound({
            ...playHistory[currentIndex-1],
            toStop : false,
            toPlay : true
        }));
    }

    goToNextSound = async () => {
        const { playSound, playHistory } = this.props;
        let currentIndex = playHistory.map((e)=> e._id).indexOf(playSound._id);
        await store.dispatch(setPlaySound({
            ...playHistory[currentIndex+1],
            toStop : false,
            toPlay : true
        }));
    }

    render = () => {
        const { playSound, playHistory } = this.props;  
        var isPlaying = false, hasHistory = false, hasNext = false;
        if(playSound && playSound.tags){
            isPlaying = playSound.toPlay ? true : false;
            hasHistory = playHistory.length > 1 && (playHistory[0]._id != playSound._id);
            hasNext = 
                // Is not at the end
                playHistory[playHistory.length-1]._id != playSound._id 
                // Is the first
                || ((playHistory[0]._id == playSound._id) && playHistory[playHistory.length] > 1)
        }
        
        return (
            <div className='soundplayer-container'>
                {playSound && playSound._id ? <SoundProgressBar/> : null}
                <div className='soundplayer'>
                    <div className='player not-mobile'>
                        <div className='vertical-center'>
                            <IconButton disabled={!hasHistory} onClick={this.goToPrevSound}>
                                <SkipPrevious style={{color : getCSSVariableValue('--background-color-inverse')}}/>
                            </IconButton>
                        </div>
                        <div className='vertical-center'>
                            <IconButton onClick={ () => this.onChange(isPlaying)} >
                                {isPlaying ? <StopCircleOutlined style={{color : getCSSVariableValue('--background-color-inverse')}}/> :  <PlayArrow style={{color : getCSSVariableValue('--background-color-inverse')}}/>}
                            </IconButton>
                        </div>
                        <div className='vertical-center'>
                            <IconButton disabled={!hasNext} onClick={this.goToNextSound}>
                                <SkipNext style={{color : getCSSVariableValue('--background-color-inverse')}}/>
                            </IconButton>
                        </div>
                    
                    </div>
                    <div className='sound-container'>
                        <div className='pack'>
                            {playSound && playSound._id ? 
                            <div className='grid-container'>
                                <div className='image-container-soundplayer'>
                                    <Animation image={playSound.imageUrl} small={true}/>
                                </div>
                                <div className='text-container vertical-align'>
                                    <h5 className='bold-text'>{truncateString(playSound.name, 17)}</h5>
                                    <>
                                        <h6 className='tag inline' style={{marginLeft : 0}} >{playSound.tags[0]}</h6>
                                        {playSound.tags.length > 1 ? 
                                        <h6 className='tag inline' style={{marginLeft : 5}} >{playSound.tags[1]}</h6>
                                        : null}
                                    </>
                                </div>
                                <div className='tag-container vertical-align' style={{width : 100}}>
                                    <h4 className='text-center'>Key</h4>
                                    <h5 className='text-center text-grey'>
                                        {playSound.keys.length > 0 ? `${playSound.keys[0]} ${playSound.keys[1] ? playSound.keys[1] : ''}`: "--"}
                                    </h5>
                                </div>
                                <div className='tag-container'>
                                    <h4 className='text-center'>BPM</h4>
                                    <h5 className='text-center text-grey'>{`${playSound.bpm || "--"}`}</h5>
                                </div> 
                            </div> : 
                            <div className='flex-container vertical-align'>
                                <img src={loadingGif} className='gif-image'></img>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='slider-container not-mobile'>
                        <SliderContainer/>
                    </div>
                </div>
            </div>
            

        )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user,
        playSound : state.playSound,
        playHistory : state.playHistory
    };
  }

  export default connect(mapStateToProps)(Soundplayer);
