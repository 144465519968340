import { Component } from 'react';
import './index.scss';
import { Mastercard, Visa, Paypal, Amex } from 'react-payment-logos/dist/flat-rounded';
import { SocialIcon } from 'react-social-icons'
import variables from '../../../_export.scss';
import { logo_color } from '../../../assets/images';
import { Link } from 'react-router-dom';

class Footer extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    render = () => {

        const { width, full } = this.props;
        const showAllFooter = width > 50;
        return (
            <div className="footer-container" style={{width :`${width || 100}%`}}>
                {full ? <div className='footer-total text-center'>
                    <h4 className='text-grey'>Sounds</h4>
                    <h4 className='text-grey'>Packs</h4>
                    <img src={logo_color}/>
                    <h4 className='text-grey'>FAQ</h4>
                    <h4 className='text-grey'>Pricing</h4>
                    <h4 className='text-grey'>Blog</h4>
                </div> : null}

                <div className='grid-container' style={{gridTemplateColumns : "33% 34% 33%"}}>
                    <div style={{paddingTop : 8}}>
                        <h6 className='inline text-grey' style={{marginLeft : 10}}>© 2024, Vertate</h6>
                        {showAllFooter ? 
                        <>
                            <Link to={'/refund-policy'}><h6 className='inline text-grey no-tablet' style={{marginLeft : 10}}>Refund Policy</h6></Link>
                            <Link to={'/privacy-policy'}><h6 className='inline text-grey not-mobile' style={{marginLeft : 10}}>Privacy Policy</h6></Link>
                            <Link to={'/terms-and-services'}><h6 className='inline text-grey not-mobile' style={{marginLeft : 10}}>Terms And Conditions</h6></Link>
                        </> : null}
                    </div>
                    <div className='align-center' style={{maxHeight : 'inherit'}}>
                        <SocialIcon style={{width : 30, height : 30, marginTop : 5}} bgColor='transparent' fgColor={variables.greyColor} target='__blank' url="https://www.instagram.com/_vertate" />
                        <SocialIcon style={{width : 30, height : 30, marginTop : 5}} bgColor='transparent' fgColor={variables.greyColor} target='__blank' url="https://www.tiktok.com/@_vertate" />
                    </div>
                    <div style={{maxHeight : 'inherit'}}>

                        <div className='align-right cards-container'>
                            <div className='inline'>
                                <Mastercard width={30} height={20} style={{marginLeft : 10}}/>
                            </div>
                            {showAllFooter ? 
                            <>
                                <div className='inline not-mobile'>
                                    <Visa  width={30} height={20} style={{marginLeft : 10}}/>
                                </div>
                                <div className='inline no-tablet'>
                                    <Amex  width={30} height={20} style={{marginLeft : 10}}/>
                                </div>
                                <div className='inline not-mobile'>
                                    <Paypal  width={30}  height={20}style={{marginLeft : 10}}/>
                                </div>
                            </>
                            : null}
                        </div>
                        
                    </div>
                </div>
            </div>
            )
    }
    
}

export default Footer;