import ListContainer from '../../../components/ListContainer';
import { Component } from 'react';
import { connect } from 'react-redux';
import TagsContainer from '../../../components/Tags';
import './index.scss';
import UrlNavigatorSingleton from '../../../classes/UrlNavigator';
import UserAPI from '../../../classes/User';
import { Animation } from '../../../components/Animation';
import { Add, DownloadOutlined, FavoriteBorderOutlined, FavoriteRounded, FileDownloadOutlined, PlayArrow } from '@mui/icons-material';
import store from '../../../redux/store';
import { setPlaySound } from '../../../redux/actions/playSound';
import { setPlayHistory } from '../../../redux/actions/playHistory';
import { getCSSVariableValue, getSizeAndOffsetFromPageNumber } from '../../../classes/Helpers';
import { setDownload } from '../../../redux/actions/downloadItem';
import SkeletonContainer from '../../../components/SkeletonContainer';
import { IconButton } from '@mui/material';
import { MetaTags } from 'react-meta-tags';

class PackPage extends Component{
    constructor(props){super(props); this.state = {isLoaded : false, pack : null, filter : {}, pages : 1, total : 0, page : 1}}

    componentDidMount = async () => {
        await this.updateFiltersUI();
    }

    updateFiltersUI = async (params={}) => {
        this.setState({isLoaded : false})
        let pack_id = UrlNavigatorSingleton.getPackId();

        const { size, offset } = getSizeAndOffsetFromPageNumber(params.page || this.state.page);

        let { pack, total, pages } = await UserAPI.getPackByIdAsync({
                size,
                offset,
                pack_id
        });
        this.setState({...this.state, pack, total, pages, isLoaded : true});
    }

    setTags = (filter) => {
        this.setState({filter : filter})
    }

    removeTag = (tag, tagType) => {
        this.props.removeTag(tag, tagType);
    }

    onChangePage = async (newPage) => {
        if(this.state.page == newPage){return}
        this.setState({page : newPage});
        await this.updateFiltersUI({page : newPage});   
    }

    buyPack = async () => {
        this.setState({isLoading : true})
        const { pack } = this.state;
        let res = await UserAPI.buyPack({pack});
        if(res.status == 200){
            this.state.pack.isOwned = true;
        }
        this.setState({isLoading : false});
    }

    downloadFile = async () => {
        await store.dispatch(setDownload(this.state.pack));
    }

    startPlayingPack = async () => {
        const { pack } = this.state;
        // Get only first 5 sounds
        let selectedSounds = pack.sounds.slice(0, 5);

        await selectedSounds.map( async s => {
            return await store.dispatch(setPlayHistory({
                ...s
            }))
        })  

        await store.dispatch(setPlaySound({
            ...selectedSounds[0],
            toPlay : true,
            toStop : false
        }))
        this.setState({isLoading : false});
    }

    addToFavorite = async () => {
        this.setState({isLoading : true});
        let res = await UserAPI.addPackToFavorites({pack : this.state.pack});
        if(res.status == 200){
            this.state.pack.isFavorite = !this.state.pack.isFavorite;
        }
        this.setState({isLoading : false});
    }

    render = () => {
        const { pack, filter, total, pages,  isLoaded, isLoading } = this.state;
        if(!pack){return null}
        return (
            <div className='container-sounds-in-pack' >

                <MetaTags>
                    <title>Pack | {pack.name}</title>
                    <meta name="description" content={pack.description} />
                    <meta property="og:title" content={pack.name} />
                </MetaTags>
                <div className='pack-page pack-grid'>
                    <div className='flex-container mobile-column'>
                        <div className='image-pack'>
                            <Animation image={pack.imageUrl} width={200}/>
                        </div>
                        <div className='text-pack'>
                            <div className='flex-container' style={{height : 20}}>
                                <h5 className='text-left tag-outline'>Originals</h5>
                                <h5 className='text-left tag-primary'>{pack.cost} Credits</h5>
                                <h5 className='text-grey' style={{marginLeft: 10, marginTop : 4}}>{total || 0} Sounds</h5>
                            </div>
                            <h2 className='text-left' style={{marginTop: 10}}>{pack.name}</h2>
                            <div className='flex-container'>
                                <div className='tags-buy-container'>
                                    <div className='flex-container'>
                                    {pack.tags.map( (tag, index) => {
                                        return (
                                            <h5 key={index} className='tag'>{tag}</h5>
                                        )
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className='flex-container container-buypack'>
                                <div className='buy-container'>
                                    <div className='flex-container'>
                                        {pack.isOwned ? 
                                        <button disabled={isLoading} onClick={this.downloadFile} className='btn flex-container'>
                                            <FileDownloadOutlined style={{marginRight : 5}}/>
                                            <h4 style={{marginTop : 2}}>Download Pack</h4>
                                        </button>
                                        : 
                                        <button disabled={isLoading} onClick={this.buyPack} className='btn btn-primary flex-container'>
                                            <Add style={{color : getCSSVariableValue('--background-color-inverse'), marginRight : 5}}/>
                                            <h4 style={{marginTop : 2}}>Buy Pack </h4>
                                        </button>}
                                        <button onClick={this.startPlayingPack} className='btn btn-outlined flex-container' style={{marginLeft : ".5rem"}}>
                                            <PlayArrow style={{color : getCSSVariableValue('--background-color-inverse'), marginRight : 5}}/>
                                            <h4 style={{marginTop : 2}}>Preview</h4>
                                        </button>
                                        {pack.isFavorite ? 
                                            <IconButton aria-label="Example" className='favorite-icon' onClick={this.addToFavorite}>
                                                <FavoriteRounded style={{color : getCSSVariableValue('--background-color-inverse')}}/>
                                            </IconButton>
                                        :     
                                            <IconButton aria-label="Example" onClick={this.addToFavorite}>
                                                <FavoriteBorderOutlined/>
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='list-container pack-container-description'>
                        <h5 className='text-left text-grey'>{pack.description}</h5>
                    </div>
                    <div className='list-sounds'>
                        <h3 className=''>Sounds</h3>
                        <TagsContainer 
                        removeTag={this.removeTag}
                        setTags={this.setTags} tagsItems={filter}/>
                    </div>
                </div>
            {isLoaded ? 
                <ListContainer total={total} pages={pages} page={this.state.page} onChangePage={this.onChangePage} sounds={pack.sounds}/>
            : <SkeletonContainer type={'list'} count={20}/>}
        </div>
        )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user,
        pack : state.pack,
        playSound : state.playSound,
        playHistory : state.playHistory,

    };
  }

  export default connect(mapStateToProps)(PackPage);
