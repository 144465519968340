const defaultState = {
    name        : '', // ex: Rui Pedro
    email       : '', // ex: ruibaz223
    company     : '', // ex: 10
    username    : '',
    password    : '', // ex: rui@gmail.com
    // MACROS
    SUBMIT      : false,
    STATUS      : null,
    VALID       : false,
};
 
export default function registerReducer (state = defaultState, action) {
    switch (action.type) {
        case 'INSERT_REGISTER':
            state[action.key] = action.value;
            verifyInfo(state);
            return Object.assign({}, state)
        case 'CALLBACK':
            state[action.key] = action.value;
            return Object.assign({}, state)
        default :
            return state;
    }

    function verifyInfo(state){
        let valid = false;
        if(     state['name'] != ''
            && state['email'] != ''
            && state['company'] != ''
            && state['password'] != ''
            )
                valid = true;
        state['VALID'] = valid;
    }
}