import { combineReducers } from 'redux';
import user from './user';
import register from './register';
import sounds from './sounds';
import packs from './packs';
import mySounds from './mySounds';
import myPacks from './myPacks';
import allFilters from './allFilters';
import setFilters from './filters';
import playSound from './playSound';
import playHistory from './playHistory';
import controls from './controls';
import route from './route';
import searchQuery from './searchQuery';
import downloadItem from './downloadItem';
import notification from './notification';

const createRootReducer = (history) => combineReducers({
    user,
    sounds,
    register,
    playHistory,
    notification,
    packs,
    downloadItem,
    playSound,
    mySounds,
    searchQuery,
    myPacks,
    allFilters,
    route,
    setFilters,
    controls
});
 
export default createRootReducer;