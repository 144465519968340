  
export const SET_PLAY_HISTORY = 'SET_PLAY_HISTORY';

export function setPlayHistory(data) {
    return {
      type: SET_PLAY_HISTORY,
      action : data
    };
}
  
  