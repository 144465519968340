import { SET_PLAY_SOUND } from "../actions/playSound";

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PLAY_SOUND : {
            return {...action.action, prevSound : state._id}
        }
      default:
        return state;
    }
  }