import { RemoveCircleOutline } from '@mui/icons-material';
import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';

const tagTypes = ["instruments", "genres", "tags"]

class TagsContainer extends Component{
    constructor(props){
        super(props)
        this.state = {
            tabSelected : {}
        }
    }

    handleChange = (tag, tagType) => {
        this.props.removeTag(tag, tagType)
    };
    
    render = () => {
        const {tagsItems} = this.props;
        if(!tagsItems || !tagsItems.tags){return null}
        return (
            <div className='container-tags flex-container'>
                {tagTypes.map( (tagType, index) => {
                    let tags = tagsItems[tagType];
                    if(!tags || tags.length == 0){return null}
                    return (
                        tags.map((tag, index) => {
                            return(
                                <div key={index} className='flex-container tag-primary'>
                                    <h5 className='text-primary' key={index}>{tag}</h5>
                                    <IconButton 
                                    onClick={() => this.handleChange(tag, tagType)}
                                    style={{margin : 0,padding : 0, marginLeft : 5}}>
                                        <RemoveCircleOutline style={{width : 12, margin : 0, marginTop : -3}}/>
                                    </IconButton>
                                </div>
                            )
                        })
                    )
                })}
                
            </div>
        )
    }
    
}


export default TagsContainer;
