import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import PackContainer from '../PackContainer';

const options = {
    size : 280,
    minSize: 100,
    gutter: 3,
    provideProps: true,
    numCols: 20,
    fringeWidth: 100,
    yRadius: 200,
    xRadius: 400,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 4
}

class BubbleContainer extends Component{
    constructor(props){super(props)}


    render = () => {
        const { children } = this.props;
        return (
            <div>
                <BubbleUI options={this.props.options || options} className="myBubbleUI">
                    {children}
                </BubbleUI>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(BubbleContainer);
