import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import ContainerDropDown from '../Container';
import CheckBox from '../../CheckBox';
import { GraphicEq, Piano } from '@mui/icons-material';


class MultiSelectDropDown extends Component{
    constructor(props){
        super(props)
        this.state = {
            age : null
        }
    }

	handleChange = (name, event) => {
		let value = event.target.value;
		this.setState({item : value});
		this.props.onChangeFilters(this.props.itemKey, name, value);
	};

	emptyDropDown = () => {
		this.props.emptyDropDown(this.props.itemKey);
	}
    
    render = () => {
		const { title, list, filters, itemKey, id} = this.props;
		let icon = itemKey == 'instruments' ? <Piano/> : <GraphicEq/>;
        return (
			<ContainerDropDown        
				id={id}
                iconTitle={icon}
				emptyDropDown={this.emptyDropDown} keyItem={itemKey} filters={filters} title={title}>
				<div>
					{list.map( (item, index) => {
						return (
							<div key={index} className='selectorMultiSelect'>
								<div className='inline align' style={{marginTop : 1}}>
									<CheckBox 
										key={index}
										filters={filters} 
										keyItem={itemKey} 
										valueItem={item.subType}
										handleChange={(event) =>this.handleChange(item.subType, event)}/>
								</div>
								<div className='inline align' style={{marginLeft : 5}}>
									<h5 className='inline'>{item.subType}</h5>
									<h5 className='inline text-grey'>{item.amount}</h5>
								</div>
							</div>
						)
                    })}
				</div>
			</ContainerDropDown>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(MultiSelectDropDown);
