  
export const SET_CONTROLS = 'SET_CONTROLS';

export function setControls(data) {
    return {
      type: SET_CONTROLS,
      action : data
    };
}
  
  