import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import variables from '../../../_export.scss';

class TabsContainer extends Component{
    constructor(props){
        super(props)
        this.state = {
            tabSelected : {},
            value : 0
        }
    }

    handleChange = (event, newValue) => {
        this.props.onChangeTab(newValue)
        this.setState({value : newValue});
    };
    
    renderA = () => {
        return (
            <div className='tab-custom-container'>
                <button className='tab-custom'>
                    <h4>Sounds</h4>
                </button>
                <button className='tab-custom'>
                    <h4>Packs</h4>
                </button>
            </div>
        )
    }

    render = () => {
        return (
        <div className='list-container'>
            <TabContext value={this.state.value}>
                <Box sx={{ borderBottom: 1, borderColor : variables.borderColor }}>
                    <Tabs
                        TabIndicatorProps={{
                            style: {
                            backgroundColor: variables.primaryColor
                            }
                        }}
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="Tabs"
                    >
                        <Tab
                            value={0}
                            label="Sounds"
                        />
                        <Tab
                            value={1}
                            label="Packs"
                        />
                    </Tabs>
                </Box>
            </TabContext>
        </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(TabsContainer);
