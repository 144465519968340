import ListContainer from '../../../components/ListContainer';
import { Component } from 'react';
import { connect } from 'react-redux';
import DropDownsContainer from '../../../components/DropDowns';
import TagsContainer from '../../../components/Tags';
import UserAPI from '../../../classes/User';
import { fromTagsToFilter, getSizeAndOffsetFromPageNumber } from '../../../classes/Helpers';
import SkeletonContainer from '../../../components/SkeletonContainer';
import MetaTags from 'react-meta-tags';

class SoundsPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading : false,
            sounds : [],
            total : 0,
            searchQuery : '',
            page : 1,
            pages : 1,
            filters : {
                genres : [],
                instruments : [],
                key : [],
                type : [],
                tags : []
            }
        }
    }

    componentWillReceiveProps = async(props) => {
        const { searchQuery } = props;
        if(searchQuery != this.state.searchQuery){
            this.setState({searchQuery : searchQuery, page : 1});
            this.updateFiltersUISearch({searchQuery, page : 1});
        }
    }

    componentDidMount = async () => {
        const { searchQuery } = this.props;
        if(searchQuery){
            this.setState({searchQuery : searchQuery});
            await this.updateFiltersUISearch({searchQuery});
        }else{
            await this.updateFiltersUI();
        }
    }

    updateFiltersUISearch  = async (params={}) => {
        this.setState({isLoaded : false});
        const { allFilters } = this.props;
        const { size, offset } = getSizeAndOffsetFromPageNumber(params.page || this.state.page);
        let { sounds, total, pages, tags } = await UserAPI.getSoundsByQuery({
            size,
            offset,
            search_query : (params.searchQuery != null) ? params.searchQuery : this.state.searchQuery
        });
        this.setState({
            ...this.state, sounds, total, pages, page : params.age || this.state.page,
            filters : fromTagsToFilter(tags, {
            ...allFilters,
            instruments : allFilters.sounds.instruments.map(value => value.subType),
            genres : allFilters.sounds.genres.map(value => value.subType),
        }), isLoaded : true});
    }

    updateFiltersUI = async (params={}) => {
        this.setState({isLoaded : false});
        const { size, offset } = getSizeAndOffsetFromPageNumber(params.page || this.state.page);
        let { sounds, total, pages } = await UserAPI.getSoundsAsync({
            size,
            offset,
            user_id : this.props.user_id,
            myFavorites : this.props.myFavorites,
            mySounds : this.props.mySounds,
            genres : this.state.filters.genres,
            instruments : this.state.filters.instruments,
            keys : this.state.filters.key,
            tags : this.state.filters.tags,
            type : this.state.filters.type[0]
        });
        this.setState({...this.state, sounds, total, pages, isLoaded : true});
    }

    setTags = async (newFilters) => {
        this.setState({filters : {...newFilters}});
        await this.updateFiltersUI();
    }

    removeTag = (tag, tagType) => {
        let prevFilter = this.state.filters;
        prevFilter[tagType].splice(prevFilter[tagType].indexOf(tag), 1); 
        this.setTags(prevFilter)
    }

    onChangeFilters = async (key, value) => {
        let prevFilter = this.state.filters;
        if(key == 'instruments' || key == 'genres'){
            if(!prevFilter[key].includes(value)){
                // Add
                prevFilter[key].push(value);
            }else{
                //Remove
                prevFilter[key].splice(prevFilter[key].indexOf(value), 1);
            }
        }else if(key == 'key'){
            if(value == 'Major' || value == "Minor"){
                prevFilter[key][1] = value;
            }else{
                prevFilter[key][0] = value;
            }
        }else if(key == 'type'){
            prevFilter[key][0] = value
        }
       
        this.setTags(prevFilter);
    }

    emptyDropDown = (key) => {
        let prevFilter = this.state.filters;
        prevFilter[key] = [];
        this.setTags(prevFilter);
    }

    onChangePage = async (newPage) => {
        if(this.state.page == newPage){return}
        this.setState({page : newPage});
        if(this.state.searchQuery != ''){
            await this.updateFiltersUISearch({page : newPage});
        }else{
            await this.updateFiltersUI({page : newPage});
        }
    }
    
    render = () => {
        const { filters, sounds, total, pages, isLoading, isLoaded } = this.state;
        return (
            <>
                <DropDownsContainer 
                    onChangeFilters={this.onChangeFilters}
                    emptyDropDown={this.emptyDropDown}
                    filters={filters} setTags={this.setTags} 
                />
                <TagsContainer 
                removeTag={this.removeTag}
                setTags={this.setTags} tagsItems={filters}/>
                {isLoaded ? 
                    <ListContainer total={total} pages={pages} page={this.state.page} onChangePage={this.onChangePage} sounds={sounds}/>
                : <SkeletonContainer type={'list'} count={20}/>}
            </>
          
        )
    }
}

function mapStateToProps(state) {
    return {
        user : state.user,
        searchQuery : state.searchQuery,
        allFilters : state.allFilters
    };
  }

  export default connect(mapStateToProps)(SoundsPage);
