import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ExpandedCard } from '../ExpandedCard';
import FilterListContainer from './FilterListContainer';
import SeeMore from '../SeeMore';
import ListItem from '../ListItem';
import { Pagination } from '@mui/material';
import { formatNumberWithCommas } from '../../classes/Helpers';

class ListContainer extends Component{
    constructor(props){
        super(props)
        this.child = {};
        this.state = {
            itemSelected : {}
        }
    }

    componentDidMount = () => {
        // Add event listeners
        window.addEventListener('keydown', this.handleKeyboard);
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.handleKeyboard);
    }

    handleKeyboard = async (event) => {
        const { sounds } = this.props; 
        const { itemSelected } = this.state;
        if(!itemSelected){return }

        let onSearchBar = document.activeElement.id === 'search_bar';
        if(onSearchBar){
            return;    
        }
        if(!itemSelected._id){
            /* If Space is pressed, play the first sound */
            if(event.key === ' '){
                console.log("here")
                let item = sounds[0];
                console.log("item", item, "child", this.child)
                this.setState({itemSelected : item});
                this.child[item._id.toString()].playSound();
                document.getElementById(`sound-${item._id}`).scrollIntoView();
                return;
            }
        }
        if(sounds.length <1){return }
        const targetIndex = sounds.findIndex(item => item._id === itemSelected._id);

        switch (event.key) {
            case ' ' : {
                /* If a sound is selected, play it */
                this.child[itemSelected._id.toString()].playSound();
                document.getElementById(`sound-${itemSelected._id}`).scrollIntoView();
                break;
            }
            case 'ArrowUp': {
                if (targetIndex > 0) {
                    event.preventDefault(); // <-- prevent the default action
                    const itemBefore = sounds[targetIndex - 1];
                    this.child[itemBefore._id.toString()].playSound();
                    document.getElementById(`sound-${itemBefore._id}`).scrollIntoView();
                    break;
                }else if( targetIndex === 0) {
                    /* Change Page */
                    event.preventDefault(); // <-- prevent the default action
                    this.setState({itemSelected : {}})
                    let copyEvent = {};
                    copyEvent.preventDefault = () => event.preventDefault;
                    copyEvent.key = 'ArrowLeft'; 
                    await this.handleKeyboard(copyEvent);
                    break;
                }else{
                    return
                }
            }
            case 'ArrowDown': {
                if (targetIndex !== -1 && targetIndex < sounds.length - 1) {
                    event.preventDefault(); // <-- prevent the default action
                    const itemAfter = sounds[targetIndex + 1];
                    this.child[itemAfter._id.toString()].playSound();
                    document.getElementById(`sound-${itemAfter._id}`).scrollIntoView();     
                }else if( targetIndex === sounds.length - 1) {
                    /* Change Page */
                    event.preventDefault(); // <-- prevent the default action
                    this.setState({itemSelected : {}})
                    let copyEvent = {};
                    copyEvent.preventDefault = () => event.preventDefault;
                    copyEvent.key = 'ArrowRight'; 
                    await this.handleKeyboard(copyEvent);
                    break;
                }else{
                    return
                }
                break;
            };
            case 'ArrowLeft':  {
                /* Change to previous page */
                event.preventDefault(); // <-- prevent the default action
                if(this.props.page > 1){
                    await this.props.onChangePage(this.props.page - 1);
                }
                break;
            };
            case 'ArrowRight': {
                console.log(1)
                /* Change to next page */
                event.preventDefault(); // <-- prevent the default action
                if(this.props.page < this.props.pages){
                    await this.props.onChangePage(this.props.page + 1);
                }
                break;
            };
            default:    
            // Handle other keys if needed
            break;
        }
    };

    onClick = (item) => {
        this.setState({itemSelected : item})
    
    }

    unfocus = () => {
        this.setState({itemSelected : {}})
    }

    renderBackCard = () => {
        const {itemSelected} = this.state;
        if(!itemSelected.id){return}
        return (
            <ExpandedCard unfocus={() => this.unfocus()} item={itemSelected}/>        
        )
    }

    onChangePage = async (e, page) => {
        await this.props.onChangePage(page);

    }

    render = () => {
        const {sounds, total, pages, page} = this.props;
        const children = sounds.map((item, index) => {
            return (
                <ul key={index} className='list-card'>
                    <ListItem onClick={this.onClick}
                        ref={c => { this.child[item._id] = c }}
                        item={item} className="child" key={index} index={index}/>
                </ul>
            )
       });

        return (
            <>
                <h5 className='amounts-container'>{this.props.total ? formatNumberWithCommas(this.props.total) : 0} results</h5>
                <div className='list-container' style={{paddingBottom : 10}}>
                    <FilterListContainer/>
                </div>
                <div className='list-container absolute-scrollable-item absolute-scrollable-sounds'>
                    <div className='inside'>
                        <div className='list-container-child'>
                            {children}
                        </div> 
                        <div className='pagination-container'>
                            <Pagination page={page} onChange={this.onChangePage} count={pages} shape="rounded" />
                        </div>
                    </div>
                </div>

            </>
      
        )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

  export default connect(mapStateToProps)(ListContainer);
