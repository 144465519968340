
export const API_URL = process.env.REACT_APP_API_URL

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const SUBSCRIPTION_STARTER_URL = process.env.REACT_APP_SUBSCRIPTION_STARTER

export const SUBSCRIPTION_PRODUCER_URL = process.env.REACT_APP_SUBSCRIPTION_PRODUCER

export const CAMPAIGN_URL = process.env.REACT_APP_SUBSCRIPTION_CAMPAIGN

export const CAMPAIGN_CODE = process.env.REACT_APP_SUBSCRIPTION_CODE

export const STRIPE_CODE = process.env.REACT_APP_STRIPE_CODE

export const PAGE_SIZE = process.env.PAGE_SIZE || 20;

export const STRIPE_PORTAL_LINK = process.env.REACT_APP_STRIPE_PORTAL_LINK;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID|| '';

export const IS_PRODUCTION = (process.env.REACT_APP_ENV === 'production') ? true : false;

export const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL|| '';
