import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { createAvatar } from '@dicebear/core';
import { notionists } from '@dicebear/collection';


class ListChart extends Component{

    constructor(props){super(props); this.state = {};}


    renderItem = (item, index) => {

        const avatar = createAvatar(notionists, {
            seed : item.name,
        });

        const svg = avatar.toString();
        console.log(svg)
        return (
            <Link to={`/app/search/user?user_id=${item._id}`} key={index} className='list-chart-item'>
                <img className='' src={`https://api.dicebear.com/8.x/notionists/svg?seed=${item.name}`} alt={item.name}/>
                <h3 className='position-item'>{index + 1}</h3>
                <div className=' list-chart-item-info'>
                    <h4>{item.name}</h4>
                    <h4>{item.sounds_favorites.length} <span className='text-grey'>Sounds Curated</span></h4>
                </div>
            </Link>
        )
    }

    render = () => {
        var { data, title, timeline } = this.props;
        if(!data){data = [];}
        return (
            <div className='list-chart-container'>
               <h3>{title} <span className='text-grey'>{timeline}</span></h3>
                <div className='list-chart'>
                    <div className='list-chart-grid'>
                        {data.map((item, index) => {
                            if(index > 4){return null}
                            return this.renderItem(item, index);       
                        })}
                    </div>
                    <div className='list-chart-grid'>
                        {data.map((item, index) => {
                            if(index < 5){return null}
                            return this.renderItem(item, index);      
                        })}
                    </div>
                    
                </div>
            </div> 
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(ListChart);