import { SET_NOTIFICATION } from "../actions/notification";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NOTIFICATION : {
            return {...action.action}
        }
      default:
        return state;
    }
  }
  