import { loadingGif } from '../../../assets/images';
import './index.scss';
import { connect } from "react-redux";



const Loading = (props) => {
    return (
        <div className="loading-container">
            <img src={loadingGif}/>
        </div>
    )
  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(Loading);