import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';

class InputText extends Component{
    constructor(props){super(props); this.state = {isFocused : true}}

    onChange = (e) => {
        const { type } = this.props;
        let __normalizedText = e.target.value;
        if(type == ('email' || 'password')){
            __normalizedText = __normalizedText.trim();
        }
        this.props.onChange(type, __normalizedText);
    }

    handleBlur = () => {
        this.setState({isFocused : false})
    }
    render = () => {
        const { isFocused } = this.state;
        var { placeholder, title, type, error, isValid } = this.props;
        isValid = isFocused ? true : isValid;
        return (
            <div className='inputTextDiv'>
                <h5 className={`${!isValid ? 'error-text' : ''}`}>{title}</h5>
                <input className={`${!isValid ? 'error-box' : ''}`}
                    onChange={this.onChange} 
                    onBlur={this.handleBlur}
                    type={type} 
                    placeholder={placeholder}>
            </input>
                {!isValid ? <h5 className='input-error'>{error}</h5> : null}
            </div>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(InputText);
