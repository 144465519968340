import { SET_FILTERS } from "../actions/filters";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FILTERS : {
            return {...action.action}
        }
      default:
        return state;
    }
  }
  