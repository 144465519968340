import { Component } from 'react';
import { connect } from 'react-redux';
import ContainerDropDown from '../Container';
import './index.scss'
import { Shuffle } from '@mui/icons-material';
class TypeSelect extends Component{
    constructor(props){
        super(props)
        this.state = {
            age : null,
			item : []
        }
    }

    handleChange = (keyItem, event) => {
		let value = event.target.value;
		this.setState({item : value});
		this.props.onChangeFilters(this.props.itemKey, keyItem);
	};

    emptyDropDown = () => {
		this.props.emptyDropDown(this.props.itemKey);
	}
    
    renderButtonBig = ({key, keyItem}) => {
        const { filters, itemKey, valueItem } = this.props;
        var isChecked = false;
        if(filters && filters[itemKey].length){
            isChecked = filters[itemKey].includes(key);
        }
        return(
            <div className="container-input-max container-input align-center" style={{margin : '10px auto'}}>
                <label>
                    <input checked={isChecked} onChange={() => {}} onClick={(e) => this.handleChange(keyItem, e)} type="checkbox" value={key}/>
                    <span className='black-color'>{key}</span>
                </label>
            </div>
            
        )
    }

    render = () => {
		const { item } = this.state;
		const { title, filters, itemKey} = this.props;
		
        return (
			<ContainerDropDown                 
                iconTitle={<Shuffle/>}
				emptyDropDown={this.emptyDropDown} unique={true} title={title}  keyItem={itemKey} filters={filters}>
                <div className='key-container'>
                    <div className='grid-container' style={{gridTemplateColumns : "50% 50%"}}>
                        {this.renderButtonBig({key : 'One-Shots', keyItem : 'One-Shots'})}
                        {this.renderButtonBig({key : 'Loops', keyItem : 'Loops'})}
                    </div>
                </div>
			</ContainerDropDown>
        )
    }    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(TypeSelect);
