import axios from 'axios';
import { API_URL } from '../../config';

const URL = API_URL;

export const methods = {
    async googleLogin(params) {
        return await axios.post(`${URL}/api/users/googleAuth`, params);
    },
    async registerUser(params) {
        return await axios.post(`${URL}/api/users/register`, params);
    },
    async loginUser(params) {
        return await axios.post(`${URL}/api/users/login`, params)
    },
    async authUser(params){
        const { bearerToken, user_id } = params;
        return await axios.post(`${URL}/api/users/auth`, {user_id}, addOpts(bearerToken, user_id));
    },
    async requestResetPassword(params){
        return await axios.post(`${URL}/api/users/password/request`, params);
    },
    async resetPassword(params){
        return await axios.post(`${URL}/api/users/password/reset`, params);
    },
    async getFilters(){
        return await axios.post(`${URL}/api/filters/get`, {});
    },
    async getStats({type, timeline}){
        return await axios.post(`${URL}/api/stats/get`, {type, timeline});
    },
    async getTops({type, timeline}){
        return await axios.post(`${URL}/api/tops/get`, {type, timeline});
    },
    async getSounds(params){
        return await axios.post(`${URL}/api/sounds/get`, params);
    },
    async getPacks(params){
        return await axios.post(`${URL}/api/packs/get`, params);
    },
    async getPackById(params){
        return await axios.post(`${URL}/api/packs/getById`, params);
    },

    async searchBySound(params){
        const { bearerToken, user_id, filter } = params;
        return await axios.post(`${URL}/api/sounds/search`, {user_id, ...filter}, addOpts(bearerToken, user_id));
    },
    async searchByPack(params){
        const { bearerToken, user_id, filter } = params;
        return await axios.post(`${URL}/api/packs/search`, {user_id, ...filter}, addOpts(bearerToken, user_id));
    },
    async getUsersSounds(params){
        const { bearerToken, user_id, filter } = params;
        return await axios.post(`${URL}/api/users/sounds`, {user_id, ...filter}, addOpts(bearerToken, user_id));
    },
    async getUsersPacks(params){
        const { bearerToken, user_id } = params;
        return await axios.post(`${URL}/api/users/packs`, {user_id}, addOpts(bearerToken, user_id));
    },
    async getMyFavoritesSounds(params){
        const { bearerToken, user_id, isUser } = params;
        return await axios.post(`${URL}/api/users/sounds/favorites`, {user_id, isUser}, addOpts(bearerToken, user_id));
    },
    async getMyFavoritesPacks(params){
        const { bearerToken, user_id, isUser } = params;
        return await axios.post(`${URL}/api/users/packs/favorites`, {user_id, isUser}, addOpts(bearerToken, user_id));
    },
    async buySound(params){
        const { bearerToken, user_id, id } = params;
        return await axios.post(`${URL}/api/sounds/buy`, {user_id, id}, addOpts(bearerToken, user_id))
    },
    async buyPack(params){
        const { bearerToken, user_id, id } = params;
        return await axios.post(`${URL}/apvi/packs/buy`, {user_id, id}, addOpts(bearerToken, user_id))
    },
    async addSoundToFavorites(params){
        const { bearerToken, user_id, id } = params;
        return await axios.post(`${URL}/api/sounds/favorite`, {user_id, id}, addOpts(bearerToken, user_id))
    },
    async getUsersMostFavorites(params){
        return await axios.post(`${URL}/api/sounds/favorites`,  {})
    },
    async addPackToFavorites(params){
        const { bearerToken, user_id, id } = params;
        return await axios.post(`${URL}/api/packs/favorite`, {user_id, id}, addOpts(bearerToken, user_id))
    }
};



function addOpts(bearerToken, user_id){
    return {
        headers : {
            "Content-Type" : "application/json",
            "authorization" : "Bearer " + bearerToken,
            "payload"       : JSON.stringify({id : user_id})
        }
    }
}