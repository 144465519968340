  
export const SET_PLAY_SOUND = 'SET_PLAY_SOUND';

export function setPlaySound(data) {
    return {
      type: SET_PLAY_SOUND,
      action : data
    };
}
  
  