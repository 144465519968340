import { Component } from 'react';
import './index.scss';


export  class Button extends Component{
    constructor(props){super(props)}

    render = () => {
        return (
            <button onClick={this.props.onClick} className={"button btn"}>
                <h4 className='text-center'> {this.props.text} </h4>
            </button> 
        )
    }
}
    