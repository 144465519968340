  
export const SET_SOUNDS = 'SET_SOUNDS';

export function setSounds(data) {
    return {
      type: SET_SOUNDS,
      action : data
    };
}
  
  