  
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

export function setSearchQuery(data) {
    return {
      type: SET_SEARCH_QUERY,
      action : data
    };
}
  
  