import { Component } from 'react';
import './index.scss';
import BubbleUI from '../../../components/BubbleContainer';
import { Instagram } from '@mui/icons-material';


const options = {
    size : 120,
    minSize: 100,
    gutter: 3,
    provideProps: true,
    numCols: 0,
    fringeWidth: 100,
    yRadius: 200,
    xRadius: 400,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 4
}

class Testimonials extends Component{

    constructor(props){
        super(props);
        this.state = {testimonials : []}
    }

    componentDidMount(){

    }

    // TO DO
    renderTestimonial = ({name, title, tag, photoURL, instagramURL, text}) => {
        return (
            <div className='testimonial-container container-outline'>
                <div className='lf'>
                    <img src={photoURL}/>
                    <div className='text-container text-left'>
                        <h5 className='tag-outline title'>{tag}</h5>
                        <h2 className='title'>{name}</h2>
                        <h4 className='text-grey title'>{title}</h4>
                        
                    </div>
                </div>
                <div className='testimonial'>
                    <h4 className='text-grey' style={{}}>
                       {`"${text}"`}
                    </h4>
                </div>
            </div>
        )
    }

    render = () => {
     
        return (
                <div className='testimonials'>
                    <div className='text-center'>
                        <h2 className='text title'>See what professional producers have to say..</h2>
                        <h4 className='text-grey'> Vertate is used by thousands of producers worldwide</h4>
                    </div>
                    <div className='flex-testimonials'>
                        <div>
                            {this.renderTestimonial({
                                name : 'Bruno Mota',
                                title : 'Producer',
                                tag : 'Venom (Marvel)',
                                photoURL : 'https://vertate-dev.b-cdn.net/mota_2.png',
                                instagramURL : 'https://www.instagram.com/brunomotaprod/',
                                text : "A true revelation in sound design for me. Its AI-driven platform has transformed how I approach music production, offering a depth of sound textures."
                            })}
                        </div>
                        <div className='not-mobile container-test'>
                            {this.renderTestimonial({
                                name : 'HOCUS',
                                title : 'Beatmaker',
                                tag : 'Top 25 BillBoard',
                                photoURL : 'https://vertate-dev.b-cdn.net/hocus.png',
                                instagramURL : 'https://www.instagram.com/hocuskrazy/',
                                text : "Vertate's got that magic for beat-making. Its AI makes our tracks hit different, pushing us onto the Billboard charts. It's easy, versatile, and absolutely essential"
                            })}
                        </div>
                        <div className='not-mobile no-tablet container-test'>
                            {this.renderTestimonial({
                                name : 'Unorthodox',
                                title : 'Content Creator',
                                tag : '+20k Followers',
                                photoURL : 'https://vertate-dev.b-cdn.net/unort.png',
                                instagramURL : 'https://www.instagram.com/unorthodox.beats/',
                                text : "Audio is key in content creation, and Vertate has significantly enhanced the way I produce it."
                            })}
                        </div>
                    </div>
                </div>
            )
        }
    
}

export default Testimonials;
