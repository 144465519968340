import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import MultiSelectDropDown from './MultiSelectDropDown';
import TypeSelect from './TypeSelect';
import KeyDropDown from './KeyDropDown';
import { generateRandomString } from '../../classes/Helpers';

class DropDownsContainer extends Component{
    constructor(props){
        super(props)
        this.state = {
            tabSelected : {}
        }
    }

    emptyDropDown = async (key) => {
        this.props.emptyDropDown(key);
    }

    onChangeFilters = async (key, value) => {
        this.props.onChangeFilters(key, value);
    }

    render = () => {
        const {user, allFilters, packs, filters} = this.props;
        if(!allFilters.tags){return null}
        return (
            <div className='dropdown-container'>
                {/* Instruments */}
                {packs ? null : <MultiSelectDropDown 
                    id={generateRandomString()}
                    itemKey={'instruments'}
                    emptyDropDown={this.emptyDropDown}
                    onChangeFilters={this.onChangeFilters}
                    filters={filters}
                    list={allFilters.sounds.instruments} 
                    title={"Instruments"}/>}
                {/* Genre */}
                <MultiSelectDropDown 
                    id={generateRandomString()}
                    itemKey={'genres'}
                    emptyDropDown={this.emptyDropDown}
                    onChangeFilters={this.onChangeFilters} 
                    filters={filters}
                    list={packs ? allFilters.packs.genres : allFilters.sounds.genres} 
                    title={"Genres"}/>
                {/* Key */}
                {packs ? null : <KeyDropDown 
                    id={generateRandomString()}
                    itemKey={'key'}
                    emptyDropDown={this.emptyDropDown}
                    filters={filters}
                    unique={true}
                    onChangeFilters={this.onChangeFilters} list={allFilters.keys} title={"Key"}/>}
                {/* Type Select */}
                {packs ? null : <TypeSelect 
                    id={generateRandomString()}
                    itemKey={'type'}
                    emptyDropDown={this.emptyDropDown}
                    filters={filters}
                    onChangeFilters={this.onChangeFilters} title={"One-Shots & Loops"}/>}
            </div>
        )
    }
    
}

function mapStateToProps(state) {

    return {
        user :  state.user,
        allFilters : state.allFilters
    };
  }

  export default connect(mapStateToProps)(DropDownsContainer);
