import { Component } from 'react';
import './index.scss';
import Arrow, { DIRECTION } from 'react-arrows'
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import APIConnection from '../../../api';
import { image_web } from '../../../../assets/images';
import SearchBar from '../../../components/SearchBar';
import SkeletonContainer from '../../../components/SkeletonContainer';

class SecondPage extends Component{

    constructor(props){
        super(props);
        this.state = {packs : []}
    }

    componentDidMount(){

    }

    render = () => {
     
        return (
            /* Show Page of App */
            <div className='second-page'>
                <div className='container text-center'>
                    <img src={image_web}></img>
                    <div className='text-left'>
                        <div className='centered'>
                            <div className='flex-container'>
                                <h5 className='tag-outline'>One-Shots</h5>
                                <h5 className='tag-outline'>Loops</h5>
                                <h5 className='tag-outline'>Samples</h5>
                            </div>
                            <h2 className='text-primary title'>Your Exclusive Library</h2>
                            <h4 className='text-grey'> 
                                Search below And get Access to thousands of unique sounds, loops and samples. 
                            </h4>
                            <div className='container-outline'>
                                <div className='search-bar-container flex-container'>
                                    <SearchBar/>
                                    <Link to={'/signup'} className='btn btn-primary' style={{marginLeft : 20}}>Search</Link>
                                </div>
                                    <SkeletonContainer type={'list-thin'} count={3}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            )
        }
    
}

export default SecondPage;