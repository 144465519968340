import React, { useEffect } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { getCSSVariableValue } from '../../classes/Helpers';

const DarkSwitch = () => {
 const [isDarkMode, setDarkMode] = React.useState(() => 
    document.body.classList.contains('theme-dark') || 
    localStorage.getItem('theme') === 'dark'
  );

  const toggleDarkMode = (checked) => {
    document.body.classList.toggle('theme-dark', checked);
    localStorage.setItem('theme', checked ? 'dark' : 'light');
    setDarkMode(checked);
  };

  // Ensure that the theme is applied on initial load based on localStorage
  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    document.body.classList.toggle('theme-dark', currentTheme === 'dark');
  }, []);

  // Retrieve CSS variable values (ensure this function is defined and can access computed styles)
  const moonColor = getCSSVariableValue('--background-color-inverse');
  const sunColor = getCSSVariableValue('--background-color-inverse');


  return (
    <div className='not-mobile'>
    <DarkModeSwitch
      style={{ color: moonColor }}
      checked={isDarkMode}
      moonColor={moonColor}
      sunColor={sunColor}
      onChange={toggleDarkMode}
      size={15}
    />
</div>
  );
};

export default DarkSwitch;
