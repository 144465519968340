import { Component } from 'react';
import { connect } from 'react-redux';
import ContainerDropDown from '../Container';
import './index.scss'
import { MusicNote } from '@mui/icons-material';


class KeypDropDown extends Component{
    constructor(props){
        super(props)
        this.state = {
			item : {
                key : null,
                tonal : null
            }
        }
    }

	handleChange = (name, event, type) => {
		let value = event.target.value;
        if(type == 0){
            this.setState({item : name});
        }else{
            this.setState({tonal : name});
        }
		this.props.onChangeFilters(this.props.itemKey, name);
	};

    emptyDropDown = () => {
		this.props.emptyDropDown(this.props.itemKey);
	}
    

    renderButton = ({key, type}) => {
        const { filters, itemKey, valueItem } = this.props;
        var isChecked = false;
        if(filters && filters[itemKey].length){
            isChecked = filters[itemKey].includes(key);
        }

        return(
            <div className="container-input">
                <label>
                    <input checked={isChecked} onChange={() => {}} onClick={(event) =>this.handleChange(key, event, type)}  type="checkbox" value={key}/>
                    <span className='black-color'>{key}</span>
                </label>
            </div>
            
        )
    }

    renderButtonBig = ({key, type}) => {
        const { filters, itemKey, valueItem } = this.props;
        var isChecked = false;
        if(filters && filters[itemKey].length){
            isChecked = filters[itemKey].includes(key);
        }
        return(
            <div className="container-input-max container-input align-center" style={{margin : '10px auto'}}>
                <label>
                    <input checked={isChecked} onChange={() => {}}  onClick={(event) =>this.handleChange(key, event, type)} type="checkbox" value={key}/>
                    <span className='black-color'>{key}</span>
                </label>
            </div>
            
        )
    }

    render = () => {
		const { item } = this.state;
		const { title, filters, itemKey} = this.props;
		
        return (
			<ContainerDropDown 
                iconTitle={<MusicNote/>}
                emptyDropDown={this.emptyDropDown} unique={true} title={title}  keyItem={itemKey} filters={filters}>
                <div className='key-container'>
                    <div className='grid-container-key' style={{marginLeft : 15}}>
                        {this.renderButton({key : "C#", type : 0})}
                        {this.renderButton({key : "D#", type : 0})}
                        <div/>
                        {this.renderButton({key : "F#", type : 0})}
                        {this.renderButton({key : "G#", type : 0})}
                        {this.renderButton({key : "A#", type : 0})}
                    </div>
                    <div className='grid-container-key'>
                        {this.renderButton({key : "C", type : 0})}
                        {this.renderButton({key : "D", type : 0})}
                        {this.renderButton({key : "E", type : 0})}
                        {this.renderButton({key : "F", type : 0})}
                        {this.renderButton({key : "G", type : 0})}
                        {this.renderButton({key : "A", type : 0})}
                        {this.renderButton({key : "B", type : 0})}
                    </div>
                    <div className='grid-container' style={{gridTemplateColumns : "50% 50%"}}>
                        {this.renderButtonBig({key : 'Major', type : 1})}
                        {this.renderButtonBig({key : 'Minor', type : 1})}
                    </div>
                </div>
			</ContainerDropDown>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(KeypDropDown);
