import { Component } from 'react';
import { connect } from "react-redux";

class SeeMoreComponent extends Component{
  constructor(props){
    super(props); this.state = {};
  }


  render = () => {
    const {text, buttonText, onClick} = this.props;

    return (
        <div className='align-center' style={{marginBottom : 10, width : 200}}>
            <h4 className='text-white text-center' style={{margin : '10px auto'}}>{text}</h4>
            <button onClick={onClick} className='btn align-center'>
                <h4 className=''>{buttonText}</h4>
            </button>                                               
        </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user;
  return {
      user
  };
}

export default connect(mapStateToProps)(SeeMoreComponent);
