import { useState } from 'react';
import './index.scss';
import store from '../../redux/store';
import { setSearchQuery } from '../../redux/actions/searchQuery';
import { useNavigate, useSearchParams } from "react-router-dom";

const SearchBar = () =>{
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('');
    const [loaded, setLoaded] = useState(false);

    const onChange = (e) => {
        let value = e.target.value;
        setSearch(value);
    }   

    const goToSearch = async (query) => {
        await store.dispatch(setSearchQuery(query));
        if(query == ''){
            navigate(`/app/search`)
        }else{
            navigate(`/app/search?query=${query}`)
        }
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            goToSearch(search);
        }
    }

    const getQueryFromBar = () => {
        let value = searchParams.get("query");
        if(value){
            setSearch(value);
            goToSearch(value);
        }
        setLoaded(true);
    }

    if(!loaded){
        getQueryFromBar();
    }

    return (
        <div className="">
            <div className="align-left search-container">
                <input defaultValue={search} onKeyDown={handleKeyDown} onChange={onChange} id='search_bar' className="searchInput" type="text" placeholder='Search for any sound..'/>
            </div>
        </div>
    )
}



export default SearchBar;