import { SET_PLAY_HISTORY } from "../actions/playHistory";

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PLAY_HISTORY : {
            return state.concat(action.action);
        }
      default:
        return state;
    }
  }