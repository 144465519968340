import React from 'react';
import encodeWAV from 'audiobuffer-to-wav';
import Card from '../Card';
const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const audioBufferToBlob = async (audioBuffer) => {
	const wavData = encodeWAV(audioBuffer);
	return new Blob([wavData], { type: 'audio/wav' });
};

const loadAudioFile = async (url) => {
	return new Promise ((resolve, reject) => {
		// Create an XMLHttpRequest object to fetch the audio file
		var request = new XMLHttpRequest();
		request.open('GET', url, true);
		request.responseType = 'arraybuffer';

		// Set up the onload callback function
		request.onload = function() {
		// Decode the audio data from the array buffer
		audioContext.decodeAudioData(request.response, function(buffer) {
			// Call the provided callback function with the decoded buffer
			resolve(buffer);
		}, function(error) {
			console.error('Error decoding audio file:', error);
			reject(error)
		});
		};

		// Send the request to fetch the audio file
		request.send();
	})
}

class ListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buffer : null,
			audioBuffers: [],
			audioURL : props.audioURL
		};
	}

	componentDidMount = async () => {
		if(!this.state.audioURL){return}
		const files = [await loadAudioFile(this.state.audioURL)]

		// Decode audio data from selected files
		const decodedPromises = Array.from(files).map(async (file) => {
			const arrayBuffer = await file.arrayBuffer();
			return audioContext.decodeAudioData(arrayBuffer);
		});

		const decodedDatas = await Promise.all(decodedPromises);
		const layeredBuffer = this.layerSoundWaves(decodedDatas);
		const blob = await audioBufferToBlob(layeredBuffer);
		this.setState({blob : blob, buffer : layeredBuffer});
	};

	generateSound(buffer){
		// Play the layered sound wave
		const sourceNode = audioContext.createBufferSource();
		sourceNode.buffer = buffer;
		sourceNode.connect(audioContext.destination);
		sourceNode.start();
        this.child.change();
	}

	onClick = (item) => {
		this.props.onClick(item);
	}

	playSound = () => {
		this.child.playSound();
	}

	render() {

		return (
            <Card 
                index={this.props.index}
                item={this.props.item}
				ref={c => { this.child = c }}
                change={this.change} 
                onClick={this.onClick}
                    //() => this.generateSound(this.state.buffer)
            />					
			);
		}
	}

export default ListItem;
