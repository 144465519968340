import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import Modal from '../Modal';
import { Link } from 'react-router-dom';

class ModalSound extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    open = () => { this.modal.open() }

    render = () => {
        const { sound } = this.props;
        return (
			<Modal ref={(child) => this.modal = child} {...this.props} >
                <div className='up-div'>
                     {!sound.isOwned ? 
                    <button disabled={sound.isOwned} onClick={this.props.onBuy} className='text-btn'>
                       <h5>Add To Library ({sound.cost} Credit)</h5>
                    </button>
                    :
                    <div disabled={sound.isOwned} className='text-cont'>
                        <h5 className='text-grey'>Already in Library</h5> 
                    </div>}
                </div>
                <div className='bottom-div'>
                    <Link to={`/app/search/pack?pack_id=${sound.pack}`} className='text-btn'>
                        <h5>Go to Pack</h5>
                    </Link>
                </div>
		    </Modal>
        )
    }
    
}

export default ModalSound;
