import React from 'react';
import './index.scss';
import { connect } from "react-redux";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from 'react-router-dom';


const Breadcrumbs = (props) => {
    const breadcrumbs = useBreadcrumbs();
    return (
        <div className='bread-container'>
            {breadcrumbs.map(({ breadcrumb }, index) => {
                if(index < 1){return}
                const isLast = index == breadcrumbs.length-1;
                if(index == 1){
                 return (
                        <h5 key={index} className='text-grey'>{breadcrumb} <span>></span> </h5>
                    )
                }
                if(isLast){
                    return (
                        <h5  key={index} className='text-primary'>{breadcrumb}</h5>
                    )
                }
                return (
                    <Link to={breadcrumb.key}>
                        <h5  key={index} className='text-grey'>{breadcrumb} <span>></span> </h5>
                    </Link>
                )
            })}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(Breadcrumbs);