import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import { StatDownArrow } from '@chakra-ui/react';
import variables from '../../../../_export.scss';
import { IconButton } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import { logo_grey } from '../../../../assets/images';

class ContainerDropDown extends Component{
    constructor(props){
        super(props)
        this.state = {
            isSelected : false
        }
    }

	handleChange = (event) => {
		this.setState({item : event.target.value});
	};

    changeDropDownStatus = () => {
        this.setState({isSelected : !this.state.isSelected})
    }

    openDropDownStatus = () => {
        const{ id } = this.props;
        this.setState({isSelected : true});
        document.getElementById(`modal-${this.props.keyItem}-${id}`).classList.replace('hideModal', "showModal");
    }

    emptyDropDown = () => {
        this.props.emptyDropDown();
    }

    render = () => {
		const { title, filters, keyItem, unique, iconTitle, id } = this.props;
        const { isSelected } = this.state;
		if(!filters || !filters[keyItem]){return null}
        let isActive = (filters[keyItem].length > 0);
        let isMobile = window.innerWidth < 500;
        return (
			<div className='container-multiSelect modal-selector'>
                <div className={`selected-button ${isActive ? 'activeButton' : null}`} onClick={this.openDropDownStatus}>
					<h5 className='inline'>
						{isMobile ? <div className='icon-multiSelect'>{iconTitle}</div> : title}
					</h5>
                    {isActive ? 
                        unique ? 
                        <div className='inline' style={{margin : "0px 5px"}}>
                            <h5>({filters[keyItem].map(e => {return e})})</h5>
                        </div>
                        :
                        <div className='inline' style={{margin : "0px 5px"}}>
                            <h5>({filters[keyItem].length})</h5>
                        </div>
                    : null}
                    {isActive ? 
                        <IconButton style={{margin : 0, padding : 0, height : 10}} onClick={this.emptyDropDown}>
                            <CloseOutlined style={{width : 15, color : variables.backgroundColor, marginLeft : 0, marginTop : 7}}/>
                        </IconButton>
                    :
                    <div className='inline'>
                        {isMobile ? null : 
                        <StatDownArrow style={{width : 10, color : variables.textColor, marginLeft : 5}}/>}
                    </div>}
				</div>
                <div id={`modal-${keyItem}-${id}`} className={`div-multiSelectBody modal ${isSelected ? 'showModal' : 'hideModal'}`}>
                    <div className='children-container'>
                        {this.props.children}
                    </div>
                    <div className='clear-box-container'>
                        <button onClick={this.changeDropDownStatus} className='btn close-button thin-btn'>
                            <h5>Close</h5>
                        </button>
                    </div>
                </div>
		  	</div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(ContainerDropDown);
