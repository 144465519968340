import SoundsPage from '../SoundsPage';
import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import TabsContainer from '../../../components/Tab';
import PacksPage from '../PacksPage';
import GoogleAnalyticsSingleton from '../../../classes/GoogleAnalytics';
import { MetaTags } from 'react-meta-tags';
  
class SearchPage extends Component{
    constructor(props){super(props);this.state = { value : 0}}

    componentDidMount = async () => {
        GoogleAnalyticsSingleton.pageview('search');
    }

    onChangeTab = (newTab) => {
        this.setState({value : newTab});
    }

    render = () => {
        const { value } = this.state;
        const { mySounds, myFavorites, user_id } = this.props;
        
        return (
            <>
                {mySounds || myFavorites ? <></> : 
                <MetaTags>
                    <title>Search</title>
                    <meta name="description" content="Search over 100,000 sounds & packs" />
                    <meta property="og:title" content="Search Packs" />
                </MetaTags>}
                <TabsContainer 
                    onChangeTab={this.onChangeTab}
                    tabs={["Account"]}/>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={this.onChangeTab}
                >
                    <div value={value} index={0}>
                        <SoundsPage user_id={user_id} mySounds={mySounds} myFavorites={myFavorites}/>
                    </div>
                    <div value={value} index={1}>
                        <PacksPage user_id={user_id} myPacks={mySounds} myFavorites={myFavorites}/>
                    </div>
                </SwipeableViews> 
            </>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(SearchPage);
