class Cache{
    save = (key, value) => {
        localStorage.setItem(key, value);
    }

    get = (key) => {
        return localStorage.getItem(key);
    }

    saveObject = (key, obj) => {
        localStorage.setItem(key, JSON.stringify(obj));
    }

    getObject = (key) => {
        return JSON.parse(localStorage.getItem(key));
    }

}

const CacheSingleton = new Cache();

export default CacheSingleton;