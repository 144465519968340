import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Rating } from 'react-simple-star-rating'

class CommunityIcon extends Component{
    constructor(props){super(props)}


    render = () => {
        return (
            <div>
                {/*<div className='rating-container'>
                    <Rating size={25} initialValue={4.5} allowFraction={true} readonly={true}/>
                </div>*/}
                <h4 className='text-grey text-community text-bold'>Join +2,000 members</h4>

                <div className='container-communtiy-ico'>
                {new Array(5).fill(0).map( (a, index) => {
                    return (
                        <img style={{marginLeft : 20*index}} src={require(`../../../assets/images/user_data/avatar${index+1}.png`)} alt="community-icon" className='community-icon' />
                        )
                    })}
                </div>

            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(CommunityIcon);
