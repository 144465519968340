import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './index.scss';
import { connect } from "react-redux";
import { getCSSVariableValue } from '../../classes/Helpers';

const SkeletonContainer = (props) => {   
    const { count, type } = props;

    switch(type){
        case 'square' : {
            return (
                <div className='skeleton-parent-square'>
                    {new Array(count).fill(count).map((e,index) => {
                        return (<div key={index} className='skeleton-square'/>)
                    })}
                </div>
            )
        };
        case 'list' :{
            return (
                <div className='skeleton-parent-square'>
                    <Skeleton 
                        baseColor={getCSSVariableValue('--background-color-grey-2')} 
                        highlightColor={getCSSVariableValue('--background-color-grey-2')} 
                        className='skeleton-list' count={count} /> 
                </div>
            )
        };
        case 'list-thin' :{
            return (
                <div className='skeleton-parent-square'>
                    <Skeleton 
                    baseColor={getCSSVariableValue('--background-color-grey-2')} 
                    highlightColor={getCSSVariableValue('--background-color-grey-2')} 
                    className='skeleton-list-thin' count={count} /> 
                </div>
            )
        };

    }

  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(SkeletonContainer);
