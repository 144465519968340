  
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export function setNotifcation(data) {
    return {
      type: SET_NOTIFICATION,
      action : data
    };
}
  
  