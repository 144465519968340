import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

class TopBar extends Component{

    constructor(props){super(props); this.state = {};}

    render = () => {
        var { user, text } = this.props;

        return (
            <Link to={'/campaign-10gb'} className='top-bar'>
                <h3 className='title'>{text}</h3>
            </Link>
             
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(TopBar);