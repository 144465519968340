import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";

class FunFactContainer extends Component{

    constructor(props){super(props); this.state = {};}

    render = () => {
        return (
            <div>
               
            </div> 
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(FunFactContainer);