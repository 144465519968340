import { SET_SOUNDS } from "../actions/sounds";

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOUNDS : {
            return [].concat(action.action);
        }
      default:
        return state;
    }
  }
  