import APIConnection from "../api"
import store from "../redux/store";
import { setUser } from "../redux/actions/user";
import CacheSingleton from "./Cache";
import { setSounds } from "../redux/actions/sounds";
import { setMyPacks } from "../redux/actions/myPacks";
import { setMySounds } from "../redux/actions/mySounds";
import { setPacks } from "../redux/actions/packs";
import { setAllFilters } from "../redux/actions/allFilters";
import { removeEmptyArraysAndKeys, roundUpIfNotInteger } from "./Helpers";
import NotificationHandlerSingleton from "./NotificationHandler";

class User{
    constructor(){
        this.isLoaded = false;
        this.user = {};
        this.tops = {}
    }

    load = async () => {
        await this.__updateUser();
        this.isLoaded = true;
        return true;
    }

    start = async () => {
        await this.__loadInitialDataAsync();
    }

    auth = async () => {
        let user = CacheSingleton.getObject("auth");
        if(user && user._id && user.bearerToken){
            let res = await APIConnection.auth({
                user_id     : user._id, 
                bearerToken : user.bearerToken
            });
            if(res.status == 200){
                this.user = { ...res.message}
                await this.load();
                return true;
            }else{
                NotificationHandlerSingleton.onError({type : "Authentication", err : res})
            }
        }else{
        }
    }

    googleLogin = async ({googleBearerToken}) => {
        let response = await APIConnection.googleLogin({
             googleBearerToken
        });
        const { message, status } = response;
        if(status != 200){
            NotificationHandlerSingleton.onError({type : "Google Login", err : response})
        }else{
            // Set to cache Login Info
            CacheSingleton.saveObject("auth", 
            {
                _id    : message._id,
                bearerToken : message.bearerToken
            }, 10000);
            this.user = message;
            await this.auth();
        }
        return response;
    }
    
    register = async ({name, password, email}) => {
        let response = await APIConnection.register({
            name        : name, 
            password    : password,
            email       : email
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Login", err : response})}
        else{await this.login({email, password})};
        return response;
    }

    requestResetPassword = async ({email}) => {
        let response = await APIConnection.requestResetPassword({  
            email : email
        });
        const { message,status } = response;
        // Show on Modal Information
        if(status != 200){NotificationHandlerSingleton.onError({type : "Reset Password", err : response})}
        return response;
    }

    resetPassword = async ({password, token, email}) => {
        let response = await APIConnection.resetPassword({
            password : password,
            token : token,
            email : email
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Reset Password", err : response})}
        return response;
    }


    login = async ({email, password}) => {
        let response = await APIConnection.login({
            email : email, 
            password : password
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Login", err : response})}
        else{
            // Set to cache Login Info
            CacheSingleton.saveObject("auth", 
            {
                _id    : message._id,
                bearerToken : message.bearerToken
            }, 10000);

            this.user = message;
            await this.auth();
        }
        return response;
    }

    buySound = async ({sound}) => {
        let response = await APIConnection.buySound({
            sound_id : sound._id,
            user_id : this.user._id,
            bearerToken : this.user.bearerToken
        })
        const { message, status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Buy Sound", err : response, item : sound, show : false})}
        else{
            this.user.credits = response.message.credits;
            await this.__updateUser();
        }
        return response;
    }

    buyPack = async ({pack}) => {
        let response = await APIConnection.buyPack({
            pack_id : pack._id,
            user_id : this.user._id,
            bearerToken : this.user.bearerToken
        })
        const { message, status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Buy Pack", err : response, item : pack, show : false})}
        else{
            this.user.credits = response.message.credits;
            await this.__updateUser();
        }
        return response;
    }

    addPackToFavorites = async ({pack}) => {
        let response = await APIConnection.addPackToFavorites({
            pack_id : pack._id,
            user_id : this.user._id,
            bearerToken : this.user.bearerToken
        })
        const { message, status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Add to Favorites", err : response})}
        return response;
    }

    addSoundToFavorites = async ({sound}) => {
        let response = await APIConnection.addSoundToFavorites({
            sound_id : sound._id, 
            user_id : this.user._id,
            bearerToken : this.user.bearerToken
        })
        const { message, status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Add to Favorites", err : response})}
        return response;
    }

    __loadInitialDataAsync = async () => {
        await Promise.all([
            //this.__getSoundsAsync(),
            //this.__getPacksAsync(),
            //this.__getMyPacksAsync(),
            //this.__getMySoundsAsync(),
            this.__getTopWeekly(),
            this.__getFiltersAsync()
        ]);

    }


    __getFiltersAsync = async () => {
        let res = await APIConnection.getFilters({filter : {}})
        let filters = res.message;
        this.filters = filters;
        await store.dispatch(setAllFilters(filters));
        return filters;
    }
    
    __getTopWeekly = async () => {
        let res = await APIConnection.getTops({type : 'packs', timeline : 'weekly'})
        let data = res.message.data;
        this.tops.weekly = data;
        return this.tops;
    }

    getSoundsAsync = async (filter={}) => {
        if(filter.mySounds){
            return await this.__getMySoundsAsync(filter);
        }else if(filter.myFavorites){
            return await this.__getMyFavoritesSoundsAsync(filter);
            //
        }else{
            return await this.__getSoundsAsync({...filter, user_id : this.user._id});
        }
    }

    getPacksAsync = async (filter={}) => {
        if(filter.myPacks){
            return await this.__getMyPacksAsync(filter);
        }else if(filter.myFavorites){
            return await this.__getMyFavoritesPacksAsync(filter);
            //
        }else{
            return await this.__getPacksAsync({...filter, user_id : this.user._id});
        }
    }

    getSoundsByQuery = async (filter) => {
        let res = await APIConnection.searchBySound({
            user_id : this.user._id, bearerToken : this.user.bearerToken,
            filter : removeEmptyArraysAndKeys(filter)
        });
        let sounds = res.message.data;
        this.sounds = sounds;
        await store.dispatch(setSounds(sounds));

        return {
            sounds : res.message.data,
            total : res.message.total,  
            tags :  res.message.tags,  
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    getPacksByQuery = async (filter) => {
        let res = await APIConnection.searchByPack({
            user_id : this.user._id, bearerToken : this.user.bearerToken,
            filter : removeEmptyArraysAndKeys(filter)
        });
        let packs = res.message.data;
        this.packs = packs;
        await store.dispatch(setPacks(packs));

        return {
            packs : res.message.data,
            total : res.message.total,  
            tags :  res.message.tags,  
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    __getSoundsAsync = async (filter) => {
        let res = await APIConnection.getSounds({filter : removeEmptyArraysAndKeys(filter)});
        let sounds = res.message.data;
        this.sounds = sounds;
        await store.dispatch(setSounds(sounds));
        return {
            sounds,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    __getPacksAsync = async (filter) => {
        let res = await APIConnection.getPacks({filter : removeEmptyArraysAndKeys(filter)})
        let packs = res.message.data;
        this.packs = packs;
        await store.dispatch(setPacks(packs));
        return {
            packs,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    __getMySoundsAsync = async (filter) => {
        let res = await APIConnection.getMySounds({
            user_id : this.user._id, bearerToken : this.user.bearerToken,
            filter : removeEmptyArraysAndKeys(filter)
        });
        let mySounds = res.message.data;
        this.mySounds = mySounds;
        await store.dispatch(setMySounds(mySounds));
        return {
            sounds : res.message.data,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    __getMyFavoritesSoundsAsync = async (filter) => {
        let res = await APIConnection.getMyFavoritesSounds({
            user_id : filter.user_id || this.user._id, bearerToken : this.user.bearerToken,
            filter : removeEmptyArraysAndKeys(filter),
            isUser : filter.user_id ? false : true
        });
        let mySounds = res.message.data;
        this.mySounds = mySounds;

        return {
            sounds : res.message.data,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }


    __getMyFavoritesPacksAsync = async (filter) => {
        let res = await APIConnection.getMyFavoritesPacks({user_id : filter.user_id || this.user._id, 
            bearerToken : this.user.bearerToken,filter : removeEmptyArraysAndKeys(filter),
            isUser : filter.user_id ? false : true
        })
        let myPacks = res.message.data;
        this.myPacks = myPacks;

        return {
            packs : res.message.data,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }

    __getMyPacksAsync = async (filter) => {
        let res = await APIConnection.getMyPacks({user_id : this.user._id, bearerToken : this.user.bearerToken,filter : removeEmptyArraysAndKeys(filter)})
        let myPacks = res.message.data;
        this.myPacks = myPacks;
        await store.dispatch(setMyPacks(myPacks));
        return {
            packs : res.message.data,
            total : res.message.total,
            pages : roundUpIfNotInteger(res.message.total/filter.size)
        };
    }


    getPackByIdAsync = async (filter) => {
        let {message} = await APIConnection.getPackById(
            {pack_id : filter.pack_id, size : filter.size, offset : filter.offset, user_id : this.user._id}
        );
        return {
            pack : message,
            total : message.totalSounds,
            pages : roundUpIfNotInteger(message.totalSounds/filter.size)
        };
    }

    getSounds = () => this.sounds;

    getPacks = () => this.packs;

    getMySounds = () => this.mySounds;

    getMyPacks = () => this.myPacks;

    getTops = () => this.tops;

    getFilters = () => this.filters;
    
    __updateUser = async () => {        
        /* Add User Info */
        await store.dispatch(setUser({
            bearerToken : this.user.bearerToken,
            name : this.user.name,
            _id : this.user._id,
            subscription : this.user.subscription,
            credits : this.user.credits,
            register_timestamp : this.user.register_timestamp,
            email : this.user.email
        }));
    }

}

const UserAPI = new User();

export default UserAPI;