import './index.scss';
import { connect } from "react-redux";
import Countdown from "react-countdown";



const CountDownTimer = (props) => {
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        props.endedCountdown();
    } else {
        if(hours < 10) hours = '0' + hours;
        if(minutes < 10) minutes = '0' + minutes;
        if(seconds < 10) seconds = '0' + seconds;
        // Render a countdown
        return ( <>{hours}:{minutes}:{seconds}</>);
    }
    };
    return (
        <Countdown
            date={props.date}
            renderer={renderer}
        />
                  
    )
  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(CountDownTimer);