  
export const SET_MY_SOUNDS = 'SET_MY_SOUNDS';

export function setMySounds(data) {
    return {
      type: SET_MY_SOUNDS,
      action : data
    };
}
  
  