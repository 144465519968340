import useDownloader from 'react-use-downloader';
import './index.scss';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import store from '../../redux/store';
import { setDownload } from '../../redux/actions/downloadItem';
import { DownloadDoneOutlined, FileUploadOutlined } from '@mui/icons-material';
import { returnFileNameExtensionFromURL, truncateString } from '../../classes/Helpers';


const DownloadTab = (props) => {
    const { downloadItem } = props;
    const [notifications, setNotifications] = useState([]);
    const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

    useEffect(() => {
        // Function to check and add new items to notifications state
        const handleNewList = async () => {
            // Check if the item is not already in notifications state
            if (!notifications.some(existingItem => existingItem._id === downloadItem._id)) {
                downloadItem.show = true;
                // Add the item to notifications state
                setNotifications(prevNotifications => [...prevNotifications, downloadItem]);
                download(downloadItem.url, downloadItem.name + returnFileNameExtensionFromURL(downloadItem.url))
                setTimeout(async () => {
                    setNotifications(prevNotifications =>
                        prevNotifications.map(notification => 
                            (notification._id == downloadItem._id) ? {...notification, show : false} : notification)
                    );
                }, 2000);
                // Set a timeout to remove the item from notifications state after 2 seconds
                setTimeout(async () => {
                await store.dispatch(setDownload({}))
                    setNotifications(prevNotifications =>
                        prevNotifications.filter(notification => notification._id !== downloadItem._id)
                    );
                }, 3000);

            }
        };
        if(downloadItem._id){
            // Call the function when newList prop changes
            handleNewList();
        }
    }, [downloadItem]);

    const renderTab = ({item, key}) => {
        let isActive = item.show;
        return (
            <div key={key} className={`${ isActive ? "show" : "hide"} donwload-tab box`}>
                <FileUploadOutlined style={{width : 20}}/>
                <h5>{truncateString(item.name, 10)}</h5>
            </div>
        )
    }
    return (
        <div>
            {notifications.map((item, index) => (
                renderTab({item, key : index})
            ))}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        downloadItem : state.downloadItem
    };
  }

 export default connect(mapStateToProps)(DownloadTab);
