  
export const SET_ROUTE = 'SET_ROUTE';

export function setRoute(data) {
    return {
      type: SET_ROUTE,
      action : data
    };
}
  
  