import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import store from '../../redux/store';
import { setControls } from '../../redux/actions/controls';

class SliderContainer extends Component{
    constructor(props){
        super(props); 
        this.state = {
           
          
        }
    }

    handleChange = async (e) => {
        // dispatch state of controls
        let value = e.target.value;
        await store.dispatch(setControls({
            volume : value
        }))
    }

    render = () => {
        const { controls } = this.props;

        return (
            <div className=''>
                <Box sx={{ width: 150 }}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Slider aria-label="Volume" value={controls.volume} onChange={this.handleChange} />
                        <VolumeUp />
                    </Stack>
                </Box>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user,
        controls : state.controls
    };
  }

  export default connect(mapStateToProps)(SliderContainer);
