import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import $ from 'jquery';
import { setPlaySound } from '../../redux/actions/playSound';
import store from '../../redux/store';

class SoundProgressBar extends Component{
    constructor(props){
        super(props); 
        this.state = {
            prevSound : {}
        }
    }

    componentDidMount(){
        this.runAnimationAndSound(this.props);
    }

    UNSAFE_componentWillReceiveProps(props){
        const { playSound } = props;
        if(this.state.prevSound._id != playSound._id){
            /* New Sound */
            if(playSound.toPlay == true){
                /* Was paused and now is playing */
                this.stopAnimation(props);
                setTimeout( () => {this.runAnimationAndSound(props);}, 100)
            }else{
                /* Was playing and now is paused */
                this.stopAnimation(props);
            }           
        }else{
            /* Same Sound */
            if(playSound.toPlay == true && this.state.prevSound.toStop == true){
                /* Was paused and now is playing */
                this.runAnimationAndSound(props);
            }else if(playSound.toStop == true){
                /* Was playing and now is paused */
                this.stopAnimation(props);
            }   
        }
        this.setState({prevSound : playSound});
        this.updateControls(props);
    }

    stopAnimation = () => {
        var player = document.getElementById('player-1');    
        player.pause();
    }

    updateControls = (props) => {
        var player = document.getElementById('player-1');    
        player.volume = props.controls.volume/100;
    }

    runAnimationAndSound = (props) => {
        var player1,onplayhead,playerId,timeline,playhead,timelineWidth,isPlaying;
        audioPlay();
        ballSeek();

        function audioPlay() {
            var player = document.getElementById("player-1");
            player.play();
            initProgressBar();
            initOnEnd();
            isPlaying = true;
        }

        function initProgressBar() {
            player1 = document.getElementById("player-1");
            player1.addEventListener("timeupdate", timeCal);
        }

        function initOnEnd() {
            player1 = document.getElementById("player-1");
            player1.onended = async () => {
                 await store.dispatch(setPlaySound({
                    ...props.playSound,
                    toStop : true,
                    ended : true,
                    toPlay : false
                }));
            };
        }

        function timeCal() {
            var width = $("#timeline1").width();
            var length = player1.duration;
            var current_time = player1.currentTime;

            // calculate total length of value
            var totalLength = calculateTotalValue(length);
        //console.info(totalLength);
            $(".end-time").html(totalLength);

            // calculate current value time
            var currentTime = calculateCurrentValue(current_time);
            $(".start-time").html(currentTime);

            var progressbar = document.getElementById("seekObj1");
            progressbar.style.marginLeft = width * (player1.currentTime / player1.duration) + "px";
            var progressbar = document.getElementById("timeline2");
            progressbar.style.width = width * (player1.currentTime / player1.duration) + "px";
        }

        function calculateTotalValue(length) {
            var minutes = Math.floor(length / 60);
            var  seconds_int = length - minutes * 60;
        if(seconds_int < 10){
            //console.info("here");
            seconds_int = "0"+seconds_int;
            //console.info(seconds_int);
        }
            var seconds_str = seconds_int.toString()
            var  seconds = seconds_str.substr(0, 2);
                var time = minutes + ':' + seconds;
        //console.info(seconds_int)
            return time;
        }

        function calculateCurrentValue(currentTime) {
            var current_hour = parseInt(currentTime / 3600) % 24,
                current_minute = parseInt(currentTime / 60) % 60,
                current_seconds_long = currentTime % 60,
                current_seconds = current_seconds_long.toFixed(),
                current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
            return current_time;
        }

        function ballSeek() {
            onplayhead = null;
            playerId = null;
            timeline = document.getElementById("timeline1");
            playhead = document.getElementById("seekObj1");
            timelineWidth = timeline.offsetWidth - playhead.offsetWidth;

            timeline.addEventListener("click", seek);
            playhead.addEventListener('mousedown', drag);
            window.addEventListener('mouseup', mouseUp);

        }


        function seek(event) {
            var player = document.getElementById("player-1");
            player.currentTime = player.duration * clickPercent(event, timeline, timelineWidth);
        }

        function clickPercent(e, timeline, timelineWidth) {
            return (e.clientX - getPosition(timeline)) / timelineWidth;
        }

        function getPosition(el) {
            return el.getBoundingClientRect().left;
        }

        function drag(e) {
            player1.removeEventListener("timeupdate", timeCal);
            onplayhead = $(this).attr("id");
            playerId = $(this).parents("li").find("audio").attr("id");
            var player = document.getElementById(playerId);
            window.addEventListener('mousemove', dragFunc);
            player.removeEventListener('timeupdate', timeUpdate);
        }


        function dragFunc(e) {
            var player = document.getElementById(onplayhead);
            var newMargLeft = e.clientX - getPosition(timeline);

            if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
                playhead.style.marginLeft = newMargLeft + "px";
            }
            if (newMargLeft < 0) {
                playhead.style.marginLeft = "0px";
            }
            if (newMargLeft > timelineWidth) {
                playhead.style.marginLeft = timelineWidth + "px";
            }
        }

        function mouseUp(e) {
            if (onplayhead != null) {
                var player = document.getElementById(playerId);
                window.removeEventListener('mousemove', dragFunc);
                player.currentTime = player.duration * clickPercent(e, timeline, timelineWidth);
                player1.addEventListener("timeupdate", timeCal);
                player.addEventListener('timeupdate', timeUpdate);
            }
            onplayhead = null;
        }

        function timeUpdate() {
            var player = document.getElementById(onplayhead);
            var player = document.getElementById(playerId);
            var playPercent = timelineWidth * (player.currentTime / player.duration);
            player.style.marginLeft = playPercent + "px";
            // If song is over
            if (player.currentTime == player.duration) {
                player.pause();
            }

        }

    }


    render = () => {
        const { playSound } = this.props;
        if(!playSound || !playSound.tags){return null};
        return (
           <li className='container-audio-player'>
                <div className="audio-player">
                    <div id="seekObjContainer">
                        <div id="seekObj1" className="playhead" style={{marginLeft : 0}}></div>
                        <div id="timeline1"/>
                        <div id="timeline2"/>
                    </div>
                    {/*<div class="player-controls scrubber">
                        <small class="start-time"></small></div>
                    <h2 class="end-time"></h2>*/}
                    <div className="audio-wrapper">
                        <audio id="player-1" preload="auto" src={playSound.url}/>
                    </div>
                </div>
            </li>
        )
    }
    
}

function mapStateToProps(state) {
    return {
        playSound : state.playSound,
        playHistory : state.playHistory,
        controls : state.controls
    };
  }

  export default connect(mapStateToProps)(SoundProgressBar);
