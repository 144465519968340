import './index.scss';
import SearchBar from "../SearchBar";
import { Component } from "react";
import { connect } from "react-redux";
import UserData from "../UserData";

class Header extends Component{

    constructor(props){super(props); this.state = {};}

    render = () => {
        return (
            <div className={"header-grid"}>
                <div className="align-left">
                    <div className='container-search container-search-header'>
                        <SearchBar/>
                    </div>
                </div>
                <div className="top-header user-data-header">   
                   <UserData/>
                </div>
            </div> 
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(Header);